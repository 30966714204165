import { matrixClient } from 'chat/context/matrix/matrix-client';
import produce, { Draft } from 'immer';
import { Direction, MatrixEvent, Room, RoomMember, TimelineWindow } from 'matrix-js-sdk';
import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';
import {
    CreateDirectChatAsync,
    CreateDirectChatAsyncRoutine,
    CreateDirectChatHandlers,
} from './actions/create-direct-chat';
import { CreateRoomAsync, CreateRoomAsyncRoutine, CreateRoomHandlers } from './actions/create-room';
import {
    InviteUserToRoomAsync,
    InviteUserToRoomAsyncRoutine,
    InviteUserToRoomHandlers,
} from './actions/invite-user-to-room';
import { JoinRoomAsync, JoinRoomAsyncRoutine, JoinRoomHandlers } from './actions/join-room';
import { KickFromRoomAsync, KickFromRoomAsyncRoutine, KickFromRoomHandlers } from './actions/kick-from-room';
import { LeaveRoomAsync, LeaveRoomAsyncRoutine, LeaveRoomHandlers } from './actions/leave-room';
import { ConfigStore } from './matrix/config-store';
import { RoomStore } from './matrix/room-store';
import { TimelineStore } from './matrix/timeline-store';

// #region State
export type ChatState = {
    isLoggedIn: boolean;
    chatIsOpen: boolean;
    chatIsReady: boolean;
    roomsLoading: boolean;
    createRoomDialogOpen: boolean;
    createRoomLoading: boolean;
    createRoomError?: string;
    createDirectChatDialogOpen: boolean;
    createDirectChatLoading: boolean;
    createDirectChatError?: string;
    createDirectChatSuccessMessage?: string;
    inviteUserDialogOpen: boolean;
    inviteUserToRoomLoading: boolean;
    inviteUserToRoomSuccessMessage?: string;
    inviteUserToRoomError?: string;
    joinRoomLoading: boolean;
    joinRoomError?: string;
    leaveRoomLoading: boolean;
    leaveRoomError?: string;
    roomIds: string[];
    allRooms: { [roomId: string]: Room };
    directs: Room[];
    rooms: Room[];
    spaces: Room[];
    inviteRoomIds: string[];
    directIds: string[];
    inviteSpaces: Room[];
    activeRoomId?: string;
    isRoomEncrypted: boolean;
    timelineWindow?: TimelineWindow;
    timelineLoading: boolean;
    canLoadMore: boolean;
    loadingMore: boolean;
    allEvents: MatrixEvent[];
    eventIds: string[];
    pageStartIds: string[];
    timeline: { [eventId: string]: MatrixEvent };
    eventEdits: { [eventId: string]: MatrixEvent[] | undefined };
    typingMembers: RoomMember[];
    attachmentProgress: { uploading: boolean; loadedBytes?: number; totalBytes?: number };
    kickFromRoomLoading: boolean;
    kickFromRoomError?: string;
    publicRoomsDialogOpen: boolean;
    allUnreadNotificationsCount: number;
};

export const INITIAL_CHAT_STATE: ChatState = {
    isLoggedIn: !!ConfigStore.getMatrixAuthData(),
    chatIsOpen: false,
    chatIsReady: false,
    roomsLoading: false,
    createRoomDialogOpen: false,
    createRoomLoading: false,
    createRoomError: undefined,
    createDirectChatDialogOpen: false,
    createDirectChatLoading: false,
    createDirectChatError: undefined,
    createDirectChatSuccessMessage: undefined,
    inviteUserDialogOpen: false,
    inviteUserToRoomLoading: false,
    inviteUserToRoomSuccessMessage: undefined,
    inviteUserToRoomError: undefined,
    joinRoomLoading: false,
    joinRoomError: undefined,
    leaveRoomLoading: false,
    leaveRoomError: undefined,
    roomIds: [],
    allRooms: {},
    directs: [],
    rooms: [],
    spaces: [],
    inviteRoomIds: [],
    directIds: [],
    inviteSpaces: [],
    activeRoomId: undefined,
    isRoomEncrypted: false,
    timelineWindow: undefined,
    timelineLoading: false,
    canLoadMore: false,
    loadingMore: false,
    allEvents: [],
    eventIds: [],
    pageStartIds: [],
    timeline: {},
    eventEdits: {},
    typingMembers: [],
    attachmentProgress: { uploading: false, loadedBytes: undefined, totalBytes: undefined },
    kickFromRoomLoading: false,
    kickFromRoomError: undefined,
    publicRoomsDialogOpen: false,
    allUnreadNotificationsCount: 0,
};
// #endregion

// #region Sync Actions
type SetChatOpenAction = { type: 'SET_CHAT_OPEN'; open: boolean };
type ChatLoadedAction = { type: 'CHAT_LOADED' };
type LoggedInAction = { type: 'LOGGED_IN' };
type RoomSelectedAction = { type: 'ROOM_SELECTED'; roomId: string };
type RoomClosedAction = { type: 'ROOM_CLOSED' };
type RoomMemberTypingEventAction = {
    type: 'ROOM_MEMBER_TYPING';
    member: RoomMember;
};
type OpenCreateRoomDialogAction = { type: 'OPEN_CREATE_ROOM_DIALOG' };
type CloseCreateRoomDialogAction = { type: 'CLOSE_CREATE_ROOM_DIALOG' };
type OpenCreateDirectChatDialogAction = { type: 'OPEN_CREATE_DIRECT_CHAT_DIALOG' };
type CloseCreateDirectChatDialogAction = { type: 'CLOSE_CREATE_DIRECT_CHAT_DIALOG' };
type OpenInviteUserDialogAction = { type: 'OPEN_INVITE_USER_DIALOG' };
type CloseInviteUserDialogAction = { type: 'CLOSE_INVITE_USER_DIALOG' };
type OpenPublicRoomsDialog = { type: 'OPEN_PUBLIC_ROOMS_DIALOG' };
type ClosePublicRoomsDialog = { type: 'CLOSE_PUBLIC_ROOMS_DIALOG' };

export type ChatAction =
    | ChatLoadedAction
    | LoggedInAction
    | SetChatOpenAction
    | RoomSelectedAction
    | RoomClosedAction
    | RoomMemberTypingEventAction
    | OpenCreateRoomDialogAction
    | CloseCreateRoomDialogAction
    | OpenCreateDirectChatDialogAction
    | CloseCreateDirectChatDialogAction
    | OpenInviteUserDialogAction
    | CloseInviteUserDialogAction
    | OpenPublicRoomsDialog
    | ClosePublicRoomsDialog;

type LoadRoomsStartedAction = { type: 'LOAD_ROOMS_STARTED' };
type LoadRoomsFailedAction = { type: 'LOAD_ROOMS_FAILED' };
type LoadRoomsSuccessAction = {
    type: 'LOAD_ROOMS_SUCCESS';
    directs: Room[];
    rooms: Room[];
    spaces: Room[];
    inviteDirects: Room[];
    inviteRooms: Room[];
    inviteSpaces: Room[];
};

type LoadTimelineStartedAction = { type: 'LOAD_TIMELINE_STARTED' };
type LoadTimelineFailedAction = { type: 'LOAD_TIMELINE_FAILED' };
type LoadTimelineSuccessAction = {
    type: 'LOAD_TIMELINE_SUCCESS';
    events: MatrixEvent[];
    eventEdits: { [eventId: string]: MatrixEvent[] };
    canPaginate: boolean;
};

type PaginateTimelineStartedAction = { type: 'PAGINATE_TIMELINE_STARTED' };
type PaginateTimelineFailedAction = { type: 'PAGINATE_TIMELINE_FAILED' };
type PaginateTimelineSuccessAction = {
    type: 'PAGINATE_TIMELINE_SUCCESS';
    events?: MatrixEvent[];
    eventEdits?: { [eventId: string]: MatrixEvent[] };
    canPaginate: boolean;
};

type SendMessageFailedAction = { type: 'SEND_MESSAGE_FAILED' };

type UploadAttachmentStartedAction = { type: 'UPLOAD_ATTACHMENT_STARTED' };
type UploadAttachmentProgressChangedAction = {
    type: 'UPLOAD_ATTACHMENT_PROGRESS_CHANGED';
    loaded: number;
    total: number;
};
type UploadAttachmentFinishedAction = { type: 'UPLOAD_ATTACHMENT_FINISHED' };

type TimelineUpdatedAction = {
    type: 'TIMELINE_UPDATED';
    events: MatrixEvent[];
    eventEdits: { [eventId: string]: MatrixEvent[] };
};

type SetAllEventsAction = { type: 'SET_ALL_EVENTS'; events: MatrixEvent[] };

type AsyncRoutineAction =
    | LoadRoomsStartedAction
    | LoadRoomsFailedAction
    | LoadRoomsSuccessAction
    | LoadTimelineStartedAction
    | LoadTimelineFailedAction
    | LoadTimelineSuccessAction
    | PaginateTimelineStartedAction
    | PaginateTimelineFailedAction
    | PaginateTimelineSuccessAction
    | TimelineUpdatedAction
    | SetAllEventsAction
    | CreateRoomAsyncRoutine
    | CreateDirectChatAsyncRoutine
    | InviteUserToRoomAsyncRoutine
    | JoinRoomAsyncRoutine
    | LeaveRoomAsyncRoutine
    | SendMessageFailedAction
    | UploadAttachmentStartedAction
    | UploadAttachmentProgressChangedAction
    | UploadAttachmentFinishedAction
    | KickFromRoomAsyncRoutine;
// #endregion

// #region Sync Action handlers
const roomsLoaded = (draft: Draft<ChatState>, action: LoadRoomsSuccessAction) => {
    const rooms = action.rooms;
    const inviteRooms = [...action.inviteRooms, ...action.inviteDirects];
    const directs = action.directs;
    rooms.sort((a, b) => b.getLastActiveTimestamp() - a.getLastActiveTimestamp());
    inviteRooms.sort((a, b) => b.getLastActiveTimestamp() - a.getLastActiveTimestamp());
    const allRooms = [...rooms, ...inviteRooms, ...directs];
    draft.roomIds = rooms.map((r) => r.roomId);
    draft.inviteRoomIds = inviteRooms.map((r) => r.roomId);
    draft.directIds = directs.map((r) => r.roomId);

    draft.allRooms = allRooms.reduce((allRooms, room) => {
        allRooms[room.roomId] = room;
        return allRooms;
    }, {});
    draft.roomsLoading = false;
    draft.rooms = action.rooms;
    draft.directs = action.directs;
    draft.spaces = action.spaces;
    draft.inviteSpaces = action.inviteSpaces;
};

const resetRoomState = (draft: Draft<ChatState>): void => {
    draft.activeRoomId = undefined;
    draft.timelineWindow = undefined;
    draft.isRoomEncrypted = false;
    draft.timelineLoading = false;
    draft.canLoadMore = false;
    draft.loadingMore = false;
    draft.eventIds = [];
    draft.timeline = {};
    draft.eventEdits = {};
    draft.pageStartIds = [];
    draft.typingMembers = [];
    resetAttachmentState(draft);
};

const roomSelected = (draft: Draft<ChatState>, action: RoomSelectedAction) => {
    if (action.roomId === draft.activeRoomId) {
        return;
    }

    resetRoomState(draft);

    draft.activeRoomId = action.roomId;

    const activeRoom = draft.allRooms[action.roomId];
    if (activeRoom) {
        const timelineSet = activeRoom.getUnfilteredTimelineSet();
        const timelineWindow = new TimelineWindow(matrixClient, timelineSet, { windowLimit: 10 ** 7 });
        draft.timelineWindow = timelineWindow;
        draft.isRoomEncrypted = matrixClient.isRoomEncrypted(activeRoom.roomId);
    }
};

const timelineLoaded = (draft: Draft<ChatState>, action: LoadTimelineSuccessAction) => {
    draft.timelineLoading = false;
    draft.canLoadMore = action.canPaginate;
    draft.eventIds = action.events.map((e) => e.getId());
    const firstEventId = draft.eventIds[0];
    draft.pageStartIds = [firstEventId];
    draft.eventEdits = action.eventEdits;
    draft.timeline = action.events.reduce((timeline, event) => {
        timeline[event.getId()] = event;
        return timeline;
    }, {});
};

const timelinePaginated = (draft: Draft<ChatState>, action: PaginateTimelineSuccessAction) => {
    draft.loadingMore = false;
    draft.canLoadMore = action.canPaginate;

    if (action.events && action.eventEdits) {
        draft.eventIds = action.events.map((e) => e.getId());
        const firstEventId = draft.eventIds[0];
        draft.eventEdits = action.eventEdits;
        draft.timeline = action.events.reduce((timeline, event) => {
            if (!timeline[event.getId()]) {
                timeline[event.getId()] = event;
            }

            return timeline;
        }, draft.timeline);
        if (!draft.pageStartIds.includes(firstEventId)) {
            draft.pageStartIds.push(firstEventId);
        }
    }
};

const timelineUpdated = (draft: Draft<ChatState>, action: TimelineUpdatedAction) => {
    draft.eventIds = action.events.map((e) => e.getId());
    draft.eventEdits = action.eventEdits;
    draft.timeline = action.events.reduce((timeline, event) => {
        if (!timeline[event.getId()]) {
            timeline[event.getId()] = event;
        }

        return timeline;
    }, draft.timeline);
};

const resetAttachmentState = (draft: Draft<ChatState>) => {
    draft.attachmentProgress = { uploading: false, loadedBytes: undefined, totalBytes: undefined };
};

const roomMemberTyping = (draft: Draft<ChatState>, action: RoomMemberTypingEventAction) => {
    if (!draft.activeRoomId || action.member.roomId !== draft.activeRoomId) {
        return;
    }

    if (action.member.userId === matrixClient.getUserId()) {
        return;
    }

    if (action.member.typing) {
        if (!draft.typingMembers.some((m) => m.userId === action.member.userId)) {
            draft.typingMembers.push(action.member);
        }
    } else {
        draft.typingMembers = draft.typingMembers.filter((m) => m.userId !== action.member.userId);
    }
};

export const reducer: Reducer<ChatState, ChatAction> = produce(
    (draft: Draft<ChatState>, action: ChatAction | AsyncRoutineAction): void => {
        switch (action.type) {
            case 'SET_CHAT_OPEN':
                draft.chatIsOpen = action.open;
                break;
            case 'CHAT_LOADED':
                draft.chatIsReady = true;
                break;
            case 'LOGGED_IN':
                draft.isLoggedIn = true;
                break;
            case 'LOAD_ROOMS_STARTED':
                draft.roomsLoading = true;
                break;
            case 'LOAD_ROOMS_FAILED':
                draft.roomsLoading = false;
                break;
            case 'LOAD_ROOMS_SUCCESS':
                roomsLoaded(draft, action);
                break;
            case 'OPEN_CREATE_ROOM_DIALOG':
                draft.createRoomDialogOpen = true;
                break;
            case 'CLOSE_CREATE_ROOM_DIALOG':
                draft.createRoomDialogOpen = false;
                break;
            case 'CREATE_ROOM_STARTED':
                draft.createRoomLoading = true;
                draft.createRoomError = undefined;
                break;
            case 'CREATE_ROOM_FAILED':
                draft.createRoomLoading = false;
                draft.createRoomError = action.error;
                break;
            case 'CREATE_ROOM_SUCCESS':
                draft.createRoomLoading = false;
                draft.createRoomDialogOpen = false;
                roomSelected(draft, { ...action, type: 'ROOM_SELECTED' });
                break;
            case 'OPEN_CREATE_DIRECT_CHAT_DIALOG':
                draft.createDirectChatDialogOpen = true;
                break;
            case 'CLOSE_CREATE_DIRECT_CHAT_DIALOG':
                draft.createDirectChatDialogOpen = false;
                break;
            case 'CREATE_DIRECT_CHAT_STARTED':
                draft.createDirectChatLoading = true;
                draft.createDirectChatError = undefined;
                break;
            case 'CREATE_DIRECT_CHAT_FAILED':
                draft.createDirectChatLoading = false;
                draft.createDirectChatError = action.error;
                break;
            case 'CREATE_DIRECT_CHAT_SUCCESS':
                draft.createDirectChatLoading = false;
                draft.createDirectChatDialogOpen = false;
                draft.createDirectChatSuccessMessage = action.successMessage;
                roomSelected(draft, { ...action, type: 'ROOM_SELECTED' });
                break;
            case 'CLEAR_CREATE_DIRECT_CHAT_SUCCESS_MESSAGE':
                draft.createDirectChatSuccessMessage = undefined;
                break;
            case 'OPEN_INVITE_USER_DIALOG':
                draft.inviteUserDialogOpen = true;
                break;
            case 'CLOSE_INVITE_USER_DIALOG':
                draft.inviteUserDialogOpen = false;
                break;
            case 'INVITE_USER_TO_ROOM_STARTED':
                draft.inviteUserToRoomLoading = true;
                draft.inviteUserToRoomError = undefined;
                break;
            case 'INVITE_USER_TO_ROOM_FAILED':
                draft.inviteUserToRoomLoading = false;
                draft.inviteUserToRoomError = action.error;
                break;
            case 'INVITE_USER_TO_ROOM_SUCCESS':
                draft.inviteUserToRoomLoading = false;
                draft.inviteUserToRoomError = undefined;
                draft.inviteUserToRoomSuccessMessage = action.successMessage;
                break;
            case 'CLEAR_INVITE_USER_TO_ROOM_SUCCESS_MESSAGE':
                draft.inviteUserToRoomSuccessMessage = undefined;
                break;
            case 'JOIN_ROOM_STARTED':
                draft.joinRoomLoading = true;
                draft.joinRoomError = undefined;
                break;
            case 'JOIN_ROOM_FAILED':
                draft.joinRoomLoading = false;
                draft.joinRoomError = action.error;
                break;
            case 'JOIN_ROOM_SUCCESS':
                draft.joinRoomLoading = false;
                draft.joinRoomError = undefined;
                break;
            case 'KICK_FROM_ROOM_STARTED':
                draft.kickFromRoomLoading = true;
                draft.kickFromRoomError = undefined;
                break;
            case 'KICK_FROM_ROOM_FAILED':
                draft.kickFromRoomLoading = false;
                draft.kickFromRoomError = action.error;
                break;
            case 'KICK_FROM_ROOM_SUCCESS':
                draft.kickFromRoomLoading = false;
                draft.kickFromRoomError = undefined;
                break;
            case 'LEAVE_ROOM_STARTED':
                draft.leaveRoomLoading = true;
                draft.leaveRoomError = undefined;
                break;
            case 'LEAVE_ROOM_FAILED':
                draft.leaveRoomLoading = false;
                draft.leaveRoomError = action.error;
                break;
            case 'LEAVE_ROOM_SUCCESS':
                draft.leaveRoomLoading = false;
                draft.leaveRoomError = undefined;
                resetRoomState(draft);
                break;
            case 'ROOM_SELECTED':
                roomSelected(draft, action);
                break;
            case 'ROOM_CLOSED':
                resetRoomState(draft);
                break;
            case 'LOAD_TIMELINE_STARTED':
                draft.timelineLoading = true;
                break;
            case 'LOAD_TIMELINE_FAILED':
                draft.timelineLoading = false;
                break;
            case 'LOAD_TIMELINE_SUCCESS':
                timelineLoaded(draft, action);
                break;
            case 'PAGINATE_TIMELINE_STARTED':
                draft.loadingMore = true;
                break;
            case 'PAGINATE_TIMELINE_FAILED':
                draft.loadingMore = false;
                break;
            case 'PAGINATE_TIMELINE_SUCCESS':
                timelinePaginated(draft, action);
                break;
            case 'TIMELINE_UPDATED':
                timelineUpdated(draft, action);
                break;
            case 'ROOM_MEMBER_TYPING':
                roomMemberTyping(draft, action);
                break;
            case 'SET_ALL_EVENTS':
                draft.allEvents = action.events;
                break;
            case 'UPLOAD_ATTACHMENT_STARTED':
                draft.attachmentProgress.uploading = true;
                break;
            case 'UPLOAD_ATTACHMENT_PROGRESS_CHANGED':
                draft.attachmentProgress.loadedBytes = action.loaded;
                draft.attachmentProgress.totalBytes = action.total;
                break;
            case 'UPLOAD_ATTACHMENT_FINISHED':
                resetAttachmentState(draft);
                break;
            case 'SEND_MESSAGE_FAILED':
                resetAttachmentState(draft);
                break;
            case 'OPEN_PUBLIC_ROOMS_DIALOG':
                draft.publicRoomsDialogOpen = true;
                break;
            case 'CLOSE_PUBLIC_ROOMS_DIALOG':
                draft.publicRoomsDialogOpen = false;
                break;
            default:
                break;
        }
    },
);
// #endregion

// #region Async Actions
type LoadRoomsAsync = { type: 'LOAD_ROOMS' };
type LoadTimelineAsync = { type: 'LOAD_TIMELINE' };
type PaginateTimelineAsync = { type: 'PAGINATE_TIMELINE' };
type SendMessageAsync = {
    type: 'SEND_MESSAGE';
    roomId: string;
    text?: string;
    attachment?: File;
    onSuccess: () => void;
    onError: (error: Error) => void;
};
type SendTypingAsync = { type: 'SEND_TYPING'; roomId: string; isTyping: boolean };
type MarkAllAsReadAsync = { type: 'MARK_ALL_AS_READ' };

type TimelineEventAction = {
    type: 'TIMELINE_EVENT';
    room: Room;
    toStartOfTimeline: boolean;
    removed: boolean;
    liveEvent?: boolean;
};
type DecryptEventAction = {
    type: 'DECRYPT_EVENT';
    event: MatrixEvent;
};
type MatrixEventHandlerAsyncAction = TimelineEventAction | DecryptEventAction;

export type AsyncChatAction =
    | LoadRoomsAsync
    | LoadTimelineAsync
    | PaginateTimelineAsync
    | MatrixEventHandlerAsyncAction
    | SendMessageAsync
    | SendTypingAsync
    | MarkAllAsReadAsync
    | CreateRoomAsync
    | CreateDirectChatAsync
    | InviteUserToRoomAsync
    | JoinRoomAsync
    | LeaveRoomAsync
    | KickFromRoomAsync;
// #endregion

// #region Async Action handlers
export const asyncActionHandlers: AsyncActionHandlers<
    Reducer<ChatState, ChatAction | AsyncRoutineAction>,
    AsyncChatAction
> = {
    LOAD_ROOMS: ({ dispatch, signal }) => async (action) => {
        try {
            dispatch({ type: 'LOAD_ROOMS_STARTED' });
            const rooms = RoomStore.getAllRooms();
            if (!signal.aborted) {
                dispatch({ type: 'LOAD_ROOMS_SUCCESS', ...rooms });
            }
        } catch (error) {
            if (!signal.aborted) {
                dispatch({ type: 'LOAD_ROOMS_FAILED' });
            }
            console.error(error, action.type);
        }
    },
    LOAD_TIMELINE: ({ dispatch, signal, getState }) => async (action) => {
        try {
            const { timelineWindow, activeRoomId, allRooms } = getState();
            const activeRoom = activeRoomId ? allRooms[activeRoomId] : undefined;

            dispatch({ type: 'LOAD_TIMELINE_STARTED' });

            const { events, eventEdits, canPaginate } = await TimelineStore.loadTimeline(timelineWindow, activeRoom);
            if (!signal.aborted) {
                dispatch({ type: 'LOAD_TIMELINE_SUCCESS', events, eventEdits, canPaginate });
                dispatch({ type: 'SET_ALL_EVENTS', events: timelineWindow?.getEvents() || [] }); // TODO: Remove in the future
            }
        } catch (error) {
            if (!signal.aborted) {
                dispatch({ type: 'LOAD_TIMELINE_FAILED' });
            }
            console.error(error, action.type);
        }
    },
    PAGINATE_TIMELINE: ({ dispatch, signal, getState }) => async (action) => {
        try {
            const { timelineWindow, loadingMore, canLoadMore } = getState();

            if (!canLoadMore) {
                console.debug('Can not paginate anymore. Skipping ...');
                dispatch({ type: 'PAGINATE_TIMELINE_SUCCESS', canPaginate: false });
                return;
            }

            if (!timelineWindow?.canPaginate(Direction.Backward)) {
                console.debug('Can not paginate anymore. Skipping ...');
                dispatch({ type: 'PAGINATE_TIMELINE_SUCCESS', canPaginate: false });
                return;
            }

            if (loadingMore) {
                console.debug('Ongoing pagination. Skipping ...');
                dispatch({ type: 'PAGINATE_TIMELINE_SUCCESS', canPaginate: false });
                return;
            }

            dispatch({ type: 'PAGINATE_TIMELINE_STARTED' });

            const { events, eventEdits, canPaginate } = await TimelineStore.paginateTimeline(timelineWindow);
            if (!signal.aborted) {
                dispatch({ type: 'PAGINATE_TIMELINE_SUCCESS', events, eventEdits, canPaginate });
                dispatch({ type: 'SET_ALL_EVENTS', events: timelineWindow?.getEvents() || [] }); // TODO: Remove in the future
            }
        } catch (error) {
            if (!signal.aborted) {
                dispatch({ type: 'PAGINATE_TIMELINE_FAILED' });
            }
            console.error(error, action.type);
        }
    },
    TIMELINE_EVENT: ({ dispatch, signal, getState }) => async (action) => {
        try {
            const { timelineWindow, activeRoomId } = getState();

            if (action.room.roomId !== activeRoomId) {
                return;
            }

            if (action.toStartOfTimeline || !action.liveEvent) {
                return;
            }

            const { events, eventEdits } = await TimelineStore.onTimelineEvent(timelineWindow, action.removed);
            if (!signal.aborted) {
                dispatch({ type: 'TIMELINE_UPDATED', events, eventEdits });
                dispatch({ type: 'SET_ALL_EVENTS', events: timelineWindow?.getEvents() || [] }); // TODO: Remove in the future
            }
        } catch (error) {
            console.error(error, action.type);
        }
    },
    DECRYPT_EVENT: ({ dispatch, signal, getState }) => async (action) => {
        try {
            const { timelineWindow, activeRoomId } = getState();

            if (action.event.getRoomId() !== activeRoomId) {
                return;
            }

            const { events, eventEdits } = await TimelineStore.onDecryptEvent(timelineWindow);
            if (!signal.aborted) {
                dispatch({ type: 'TIMELINE_UPDATED', events, eventEdits });
                dispatch({ type: 'SET_ALL_EVENTS', events: timelineWindow?.getEvents() || [] }); // TODO: Remove in the future
            }
        } catch (error) {
            console.error(error, action.type);
        }
    },
    SEND_MESSAGE: ({ dispatch }) => async (action) => {
        try {
            if (action.attachment) {
                dispatch({ type: 'UPLOAD_ATTACHMENT_STARTED' });
                await TimelineStore.sendAttachment(action.roomId, action.attachment, ({ loaded, total }) => {
                    dispatch({ type: 'UPLOAD_ATTACHMENT_PROGRESS_CHANGED', loaded, total });
                });
                dispatch({ type: 'UPLOAD_ATTACHMENT_FINISHED' });
            }
            if (action.text?.trim()) {
                await TimelineStore.sendMessage(action.roomId, action.text);
            }
            action.onSuccess();
        } catch (error) {
            dispatch({ type: 'SEND_MESSAGE_FAILED' });
            action.onError(error as Error);
            console.error(error, action.type);
        }
    },
    SEND_TYPING: () => async (action) => {
        try {
            await matrixClient.sendTyping(action.roomId, action.isTyping, 30000);
        } catch (error) {
            console.error(error, action.type);
        }
    },
    MARK_ALL_AS_READ: ({ getState }) => async (action) => {
        const { activeRoomId, allRooms, timeline } = getState();
        const activeRoom = activeRoomId ? allRooms[activeRoomId] : undefined;
        try {
            await TimelineStore.markAllAsRead(activeRoom, timeline);
        } catch (error) {
            console.error(error, action.type);
        }
    },
    ...CreateRoomHandlers,
    ...CreateDirectChatHandlers,
    ...InviteUserToRoomHandlers,
    ...JoinRoomHandlers,
    ...LeaveRoomHandlers,
    ...KickFromRoomHandlers,
};
// #endregion
