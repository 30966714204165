import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;

    return {
        root: {
            inset: 'auto !important',
            '& .matrix-MuiPaper-root': {
                backgroundColor: isDarkMode ? 'default' : '#f6f6f7',
                width: '100%',
            },
        },
        container: {
            paddingTop: theme.spacing(6),
            transition: 'all .2s',
            position: 'relative',
        },

        backToMembers: {
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(3),
        },
    };
});
