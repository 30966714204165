import { makeStyles, Typography } from '@material-ui/core';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VideocamIcon from '@material-ui/icons/Videocam';
import { bytesToSize } from 'chat/context/matrix/utils/common';
import { useChatState } from 'chat/context/store';
import clsx from 'clsx';
import React from 'react';

export const Attachment: React.FC<{ attachment: File }> = ({ attachment }) => {
    const classes = useStyles();
    const { attachmentProgress } = useChatState();
    const { uploading, loadedBytes, totalBytes } = attachmentProgress;
    const progressPercentage = loadedBytes && totalBytes ? Math.round((loadedBytes * 100) / totalBytes) : 0;
    const attachmentSizeText =
        uploading && loadedBytes && totalBytes
            ? `Uploading: ${bytesToSize(loadedBytes)}/${bytesToSize(totalBytes)} (${progressPercentage}%)`
            : `size: ${bytesToSize(attachment.size)}`;
    const fileType = attachment.type.slice(0, attachment.type.indexOf('/'));
    return (
        <div className={classes.root}>
            <div className={clsx(classes.attachmentContainer, fileType !== 'image' && classes.icon)}>
                {fileType === 'image' && <img alt={attachment.name} src={URL.createObjectURL(attachment)} />}
                {fileType === 'video' && <VideocamIcon />}
                {fileType === 'audio' && <GraphicEqIcon />}
                {fileType !== 'image' && fileType !== 'video' && fileType !== 'audio' && <InsertDriveFileIcon />}
            </div>
            <div className={classes.info}>
                <Typography variant="h5" color="textPrimary">
                    {attachment.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {attachmentSizeText}
                </Typography>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            '& img': {
                width: 40,
                height: 40,
                maxHeight: 40,
                maxWidth: 40,
                objectFit: 'cover',
                borderRadius: 8,
            },
        },
        attachmentContainer: {
            display: 'flex',
            color: theme.palette.text.primary,
        },
        icon: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(1),
            borderRadius: 8,
        },
        info: {
            flexGrow: 1,
            marginLeft: theme.spacing(2),
        },
    };
});
