import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        radioItem: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            margin: 0,
        },
    };
});
