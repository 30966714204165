import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';
import { RoomStore } from '../matrix/room-store';
import { ChatState } from '../reducer';

type CreateRoomStartedAction = { type: 'CREATE_ROOM_STARTED' };
type CreateRoomFailedAction = { type: 'CREATE_ROOM_FAILED'; error?: string };
type CreateRoomSuccessAction = {
    type: 'CREATE_ROOM_SUCCESS';
    roomId: string;
};

export type CreateRoomAsyncRoutine = CreateRoomStartedAction | CreateRoomFailedAction | CreateRoomSuccessAction;

export type CreateRoomAsync = {
    type: 'CREATE_ROOM';
    name: string;
    topic?: string;
    isPublic?: boolean;
    isEncrypted?: boolean;
    isDirect?: boolean;
};

export const CreateRoomHandlers: AsyncActionHandlers<Reducer<ChatState, CreateRoomAsyncRoutine>, CreateRoomAsync> = {
    CREATE_ROOM: ({ dispatch, signal }) => async (action) => {
        try {
            dispatch({ type: 'CREATE_ROOM_STARTED' });
            const roomId = await RoomStore.createRoom(action);
            if (!signal.aborted) {
                dispatch({ type: 'CREATE_ROOM_SUCCESS', roomId });
            }
        } catch (error) {
            if (!signal.aborted) {
                const errcode = (error as { errcode: string }).errcode;
                const errcodes = ['M_BAD_JSON', 'M_ROOM_IN_USE', 'M_INVALID_ROOM_STATE', 'M_UNSUPPORTED_ROOM_VERSION'];

                const errorMessage = errcodes.includes(errcode) ? errcode : undefined;

                dispatch({ type: 'CREATE_ROOM_FAILED', error: errorMessage });
            }
            console.error(error, action.type);
        }
    },
};
