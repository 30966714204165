import { darken, lighten, makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = ({ attachmentProgressPercentage }: { attachmentProgressPercentage: number | undefined }) =>
    makeStyles((theme) => {
        const isDarkMode = theme.palette.type === Theme.Dark;
        const defaultBGColor = theme.palette.background.default;
        const attachmentUploadingBackgroundColor = isDarkMode
            ? lighten(defaultBGColor, 0.05)
            : darken(defaultBGColor, 0.05);
        const attachmentUploadingForegroundColor = isDarkMode
            ? lighten(defaultBGColor, 0.2)
            : darken(defaultBGColor, 0.2);
        return {
            root: {
                borderTop: `1px solid ${theme.palette.divider}`,
                padding: theme.spacing(1, 2, 1, 2),
                '& .MuiFormHelperText-root': {
                    display: 'none',
                },
            },
            container: {
                display: 'flex',
                alignItems: 'center',
                background: attachmentProgressPercentage
                    ? `linear-gradient(90deg, ${attachmentUploadingForegroundColor} ${attachmentProgressPercentage}%, ${attachmentUploadingBackgroundColor} ${attachmentProgressPercentage}%)`
                    : attachmentUploadingBackgroundColor,
                borderRadius: 8,
                padding: theme.spacing(0.5),
                paddingLeft: theme.spacing(1.5),
            },
            input: {
                '& .matrix-MuiInput-underline:before': {
                    borderBottom: 'none',
                },
                '& .matrix-MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                },
                '& .matrix-MuiInput-underline:after': {
                    borderBottom: 'none',
                },
                '& textarea': {
                    fontSize: 16,
                },
            },
            iconButton: {
                padding: 6,
                transition: 'opacity .3s',
                '& svg': {
                    fontSize: '1.75rem',
                    transition: '.3s color',
                },
            },
            iconButtonHidden: {
                opacity: 0,
                pointerEvents: 'none',
            },
            sendButton: {
                padding: '6px 5px 6px 7px',
            },
        };
    });
