export function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function diffMinutes(dt2: Date, dt1: Date): number {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

export function isNotInSameDay(dt2?: Date | null, dt1?: Date | null): boolean {
    if (!dt1 || !dt2) {
        return false;
    }

    return (
        dt2.getDay() !== dt1.getDay() || dt2.getMonth() !== dt1.getMonth() || dt2.getFullYear() !== dt1.getFullYear()
    );
}
