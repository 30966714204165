import { darken, lighten, makeStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import { Theme } from 'enum';

type StyleProps = {
    replyBorderColor: string;
};

export const useStyles = makeStyles<MuiTheme, StyleProps>((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    const colorTransform = isDarkMode ? darken : lighten;
    return {
        root: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: darken(theme.palette.background.paper, 0.03),
            },
            padding: theme.spacing(0.75, 2),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0.5, 1),
            },
        },
        senderName: {
            fontWeight: 600,
        },
        messageContent: {
            flex: 1,
            paddingLeft: theme.spacing(1.5),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(1),
            },
        },
        message: {
            ...theme.typography.body1,
            fontWeight: 500,
            color: colorTransform(theme.palette.text.primary, 0.2),
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            position: 'relative',
            maxWidth: '80%',
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
            },
            '& blockquote': {
                borderLeft: (props) => `3px solid ${props.replyBorderColor}`,
                paddingLeft: theme.spacing(1),
                paddingBottom: theme.spacing(0.5),
                '&>a:first-child': {
                    display: 'none',
                },
            },
            '& code': {
                backgroundColor: darken(theme.palette.background.paper, 0.1),
                color: '#E91E63',
                borderRadius: '2px',
                padding: '2px 6px',
                whiteSpace: 'pre-wrap',
                fontSize: '12px',
            },
            '& pre': {
                maxWidth: '100%',
                backgroundColor: darken(theme.palette.background.paper, 0.1),
                padding: theme.spacing(1),
                borderRadius: '4px',
                '& code': {
                    backgroundColor: 'transparent',
                    color: theme.palette.text.primary,
                    borderRadius: 0,
                    padding: 0,
                    whiteSpace: 'pre-wrap',
                    fontSize: '12px',
                },
            },
            '& ol': {
                paddingLeft: theme.spacing(2),
            },
            '& ul': {
                paddingLeft: theme.spacing(2),
            },
        },
        date: {
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
            width: 'max-content',
            whiteSpace: 'nowrap',
            opacity: 0.7,
        },
    };
});
