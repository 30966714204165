import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './setting-section-item.style';

type SettingSectionItemProps = {
    title: string;
    icon: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
};

export const SettingSectionItem: React.FC<SettingSectionItemProps> = ({ title, icon, onClick, disabled }) => {
    const classes = useStyles({ disabled: disabled });

    return (
        <Grid item xs={6} md={3}>
            <Box
                className={classes.root}
                onClick={() => {
                    if (disabled) return;
                    onClick();
                }}
            >
                {icon}
                <Typography className={classes.title}>{title}</Typography>
            </Box>
        </Grid>
    );
};
