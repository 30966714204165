import React from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { SecuritySectionItem } from '../security-section-item';
import { useStyles } from './message-history-visibility.style';

type MessageHistoryVisibilityProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    values: any;
};

export const MessageHistoryVisibility: React.FC<MessageHistoryVisibilityProps> = ({ setFieldValue, values }) => {
    const classes = useStyles();
    return (
        <SecuritySectionItem
            title="Message history visibility"
            content={
                <RadioGroup
                    aria-label="quiz"
                    onChange={(e) => setFieldValue('messageHistoryVisibility', e.target.value)}
                    value={values.messageHistoryVisibility}
                >
                    <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="World readable (anyone can read)"
                        className={classes.radioItem}
                    />
                    <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Member shared (since the point in time of selecting this option)"
                        className={classes.radioItem}
                    />
                    <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Member invited (since they were invited)"
                        className={classes.radioItem}
                    />
                    <FormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="Member joined (since they joined)"
                        className={classes.radioItem}
                    />
                </RadioGroup>
            }
        />
    );
};
