import React, { useCallback } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useChatDispatch } from 'chat/context/store';
import { SettingSectionItem } from '../components';

export const Invite: React.FC = () => {
    const dispatch = useChatDispatch();

    const openInviteUserDialog = useCallback(() => {
        dispatch({ type: 'OPEN_INVITE_USER_DIALOG' });
    }, [dispatch]);

    return <SettingSectionItem icon={<PersonAddIcon />} title="Add" onClick={openInviteUserDialog} />;
};
