import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';
import { RoomStore } from '../matrix/room-store';
import { ChatState } from '../reducer';

type InviteUserToRoomStartedAction = { type: 'INVITE_USER_TO_ROOM_STARTED' };
type InviteUserToRoomFailedAction = { type: 'INVITE_USER_TO_ROOM_FAILED'; error?: string };
type InviteUserToRoomSuccessAction = {
    type: 'INVITE_USER_TO_ROOM_SUCCESS';
    successMessage?: string;
};
type ClearInviteUserToRoomSuccessMessageAction = {
    type: 'CLEAR_INVITE_USER_TO_ROOM_SUCCESS_MESSAGE';
};

export type InviteUserToRoomAsyncRoutine =
    | InviteUserToRoomStartedAction
    | InviteUserToRoomFailedAction
    | InviteUserToRoomSuccessAction
    | ClearInviteUserToRoomSuccessMessageAction;

export type InviteUserToRoomAsync = {
    type: 'INVITE_USER_TO_ROOM';
    roomId: string;
    userIds: string[];
    successMessage?: string;
};

export const InviteUserToRoomHandlers: AsyncActionHandlers<
    Reducer<ChatState, InviteUserToRoomAsyncRoutine>,
    InviteUserToRoomAsync
> = {
    INVITE_USER_TO_ROOM: ({ dispatch, signal }) => async (action) => {
        try {
            dispatch({ type: 'INVITE_USER_TO_ROOM_STARTED' });
            await RoomStore.inviteUserToRoom(action);
            if (!signal.aborted) {
                dispatch({ type: 'INVITE_USER_TO_ROOM_SUCCESS', successMessage: action.successMessage });
                if (action.successMessage) {
                    setTimeout(() => dispatch({ type: 'CLEAR_INVITE_USER_TO_ROOM_SUCCESS_MESSAGE' }), 5000);
                }
            }
        } catch (error) {
            console.log(error);
            if (!signal.aborted) {
                const errcode = (error as { errcode: string }).errcode;
                console.log({ errcode });
                const errcodes = [
                    'M_BAD_JSON',
                    'M_INVALID_PARAM',
                    'M_FORBIDDEN',
                    'M_LIMIT_EXCEEDED',
                    'M_UNSUPPORTED_ROOM_VERSION',
                ];

                const errorMessage = errcodes.includes(errcode) ? errcode : undefined;

                dispatch({ type: 'INVITE_USER_TO_ROOM_FAILED', error: errorMessage });
            }
            console.error(error, action.type);
        }
    },
};
