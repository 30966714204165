export enum RoomMembership {
    Invite = 'invite',
    Join = 'join',
    Leave = 'leave',
    Kick = 'kick',
    Ban = 'ban',
    Unban = 'unban',
}

export enum MatrixEventType {
    AccountData = 'accountData',
    EventDecrypted = 'Event.decrypted',
    RoomMyMembership = 'Room.myMembership',
    RoomName = 'Room.name',
    RoomReceipt = 'Room.receipt',
    RoomRedaction = 'Room.redaction',
    RoomTimeline = 'Room.timeline',
    RoomMemberTyping = 'RoomMember.typing',
    RoomStateEvents = 'RoomState.events',
    SessionLoggedOut = 'Session.logged_out',
    Sync = 'sync',
}

export type AttachmentProgressHandler = ({ loaded, total }: { loaded: number; total: number }) => void;

export type MatrixAuthData = {
    access_token: string;
    device_id: string;
    home_server: string;
    user_id: string;
};
