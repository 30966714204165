import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useChat } from 'chat/context/store';
import { LoadingButton } from 'components';
import { FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useStyles } from './create-room-dialog.style';
import { CreateRoomForm, CreateRoomFormValues } from './create-room-form';

export const CreateRoomDialog: React.FC = () => {
    const classes = useStyles();
    const [{ createRoomDialogOpen, createRoomLoading, createRoomError }, dispatch] = useChat();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const formRef = useRef<FormikProps<CreateRoomFormValues>>(null);

    const closeDialog = () => dispatch({ type: 'CLOSE_CREATE_ROOM_DIALOG' });

    const initialValues: CreateRoomFormValues = {
        name: '',
        isEncrypted: false,
        isPublic: false,
        topic: '',
    };

    const submitForm = () => {
        formRef.current?.submitForm();
    };

    const onSubmit = (options: CreateRoomFormValues) => {
        dispatch({ type: 'CREATE_ROOM', ...options });
    };

    useEffect(() => {
        if (createRoomError) {
            formRef.current?.setSubmitting(false);
        }
    }, [createRoomError]);

    return (
        <Dialog fullWidth maxWidth="sm" open={createRoomDialogOpen} fullScreen={isMobile}>
            <DialogTitle className={classes.header} disableTypography>
                <Typography component="h4" variant="h4">
                    Create a room
                </Typography>
                <IconButton size="small" onClick={closeDialog} disabled={createRoomLoading}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CreateRoomForm {...{ initialValues, onSubmit, formRef, error: createRoomError }} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={closeDialog} variant="outlined" color="primary" disabled={createRoomLoading}>
                    Cancel
                </Button>
                <LoadingButton
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    text="Create"
                    loadingText="Creating"
                    loading={createRoomLoading}
                />
            </DialogActions>
        </Dialog>
    );
};
