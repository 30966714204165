import { Switch, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { SecuritySectionItem } from '../security-section-item';
import { useStyles } from './encryption.style';

type EncryptionProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    values: any;
};

export const Encryption: React.FC<EncryptionProps> = ({ setFieldValue, values }) => {
    const classes = useStyles();
    return (
        <SecuritySectionItem
            title="Encryption"
            content={
                <ListItem className={classes.listItem}>
                    <ListItemText secondary="Once enabled, encryption cannot be disabled.">
                        Enable room encryption
                    </ListItemText>
                    <Switch
                        checked={values.encryptionEnabled}
                        onChange={(e) => setFieldValue('encryptionEnabled', e.target.checked)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                    />
                </ListItem>
            }
        />
    );
};
