import React, { useCallback } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
    IconButton,
    Box,
} from '@material-ui/core';
import { useChat } from 'chat/context/store';
import { useStyles } from './public-rooms-dialog.style';
import CloseIcon from '@material-ui/icons/Close';
import { PublicRoomsForm } from './public-rooms-form';

export const PublicRoomsDialog: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const [{ publicRoomsDialogOpen }, dispatch] = useChat();

    const closeDialog = () => dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });

    const openCreateRoomDialog = useCallback(() => {
        dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });
        dispatch({ type: 'OPEN_CREATE_ROOM_DIALOG' });
    }, [dispatch]);

    return (
        <Dialog fullWidth maxWidth="sm" open={publicRoomsDialogOpen} fullScreen={isMobile}>
            <DialogTitle disableTypography className={classes.header}>
                <Box>
                    <Typography component="h4" variant="h4" className={classes.title}>
                        Explore rooms
                    </Typography>
                    <Typography className={classes.details}>
                        If you can not find the room you are looking for, ask for an invite or
                        <span onClick={openCreateRoomDialog} className={classes.action}>
                            {' ' + 'Create a new room.'}
                        </span>
                    </Typography>
                </Box>

                <IconButton size="small" onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PublicRoomsForm />
            </DialogContent>
            <Divider />
        </Dialog>
    );
};
