import { Box, LinearProgress } from '@material-ui/core';
import { useChat } from 'chat/context/store';
import { usePrependedMessagesCount } from 'chat/hooks/use-prepend-messages-count';
import { Spinner } from 'components';
import React, { useCallback, useEffect, useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { DateSeparator } from '../date-seperator';
import { Message } from '../message';
import { useStyles } from './timeline-panel.style';

const PREPEND_OFFSET = 10 ** 7;

export const TimelinePanel: React.FC = () => {
    const classes = useStyles();

    const virtuoso = useRef<VirtuosoHandle | null>(null);
    const atBottom = useRef(false);

    const [{ timelineLoading, canLoadMore, loadingMore, eventIds, timeline }, dispatch] = useChat();

    useEffect(() => {
        dispatch({ type: 'LOAD_TIMELINE' });
    }, [dispatch]);

    const numItemsPrepended = usePrependedMessagesCount(eventIds);

    const messageRenderer = useCallback(
        (virtuosoIndex: number) => {
            const index = virtuosoIndex + numItemsPrepended - PREPEND_OFFSET;
            const eventId = eventIds[index];
            const prevEventId = index > 0 ? eventIds[index - 1] : '__undefined__';

            const message = timeline[eventId];
            const prevEvent = timeline[prevEventId];

            return (
                <>
                    <DateSeparator {...{ message, prevEvent }} />
                    <Message {...{ message, prevEvent }} />
                </>
            );
        },
        [eventIds, numItemsPrepended, timeline],
    );

    const topReached = () => {
        if (canLoadMore) {
            dispatch({ type: 'PAGINATE_TIMELINE' });
        }
    };

    const atBottomStateChange = (isAtBottom: boolean) => {
        atBottom.current = isAtBottom;
        if (isAtBottom) {
            dispatch({ type: 'MARK_ALL_AS_READ' });
        }
    };

    const followOutput = (isAtBottom: boolean) => {
        if (timelineLoading || eventIds.length === 0) {
            return 'auto';
        }

        return isAtBottom ? 'smooth' : false;
    };

    if (timelineLoading) {
        return <Spinner />;
    }

    return (
        <Box className={classes.contentContainer}>
            <LinearProgress
                style={{ width: '100%', position: 'absolute', display: loadingMore ? undefined : 'none' }}
            />

            <Virtuoso
                ref={virtuoso}
                className={classes.virtuoso}
                alignToBottom={true}
                followOutput={followOutput}
                overscan={0}
                firstItemIndex={PREPEND_OFFSET - numItemsPrepended}
                initialTopMostItemIndex={eventIds.length ? eventIds.length - 1 : 0}
                totalCount={eventIds.length}
                itemContent={messageRenderer}
                startReached={topReached}
                atBottomStateChange={atBottomStateChange}
            />
        </Box>
    );
};
