import { Box, Typography } from '@material-ui/core';
import { isNotInSameDay } from 'chat/context/matrix/utils/common';
import { DateTime } from 'luxon';
import { MatrixEvent } from 'matrix-js-sdk';
import React from 'react';
import { useStyles } from './date-separator.style';

export type DateSeparatorProps = {
    message: MatrixEvent;
    prevEvent?: MatrixEvent;
};

const DateSeparatorComponent: React.FC<DateSeparatorProps> = ({ message, prevEvent }) => {
    const classes = useStyles();

    const messageIsNotOnSameDay = isNotInSameDay(prevEvent?.getDate(), message.getDate());
    const show = messageIsNotOnSameDay;
    if (!show) {
        return null;
    }

    const date = DateTime.fromJSDate(message.getDate() || new Date()).toFormat('MMMM dd, yyyy');

    return (
        <Box className={classes.root}>
            <Box className={classes.line} />
            <Box>
                <Typography className={classes.date}>{date}</Typography>
            </Box>
            <Box className={classes.line} />
        </Box>
    );
};

export const DateSeparator = React.memo(DateSeparatorComponent);
