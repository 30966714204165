import { Box, Typography } from '@material-ui/core';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { useChatState } from 'chat/context/store';
import { RoomMember } from 'matrix-js-sdk';
import React, { useCallback } from 'react';
import { useStyles } from './typing-panel.style';

export const TypingPanel: React.FC = () => {
    const classes = useStyles();
    const { typingMembers } = useChatState();

    const getTypingMessage = useCallback(() => {
        const getUserNameJsx = (member: RoomMember) => (
            <Typography component="b" style={{ color: colorMXID(member.userId) }}>
                {member.name || member.userId}
            </Typography>
        );

        if (!typingMembers.length) {
            return null;
        }

        const MAX_VISIBLE_COUNT = 3;

        const u1Jsx = getUserNameJsx(typingMembers[0]);
        if (typingMembers.length === 1) {
            return <Typography color="textSecondary">{u1Jsx} is typing...</Typography>;
        }

        const u2Jsx = getUserNameJsx(typingMembers[1]);
        if (typingMembers.length === 2) {
            return (
                <Typography color="textSecondary">
                    {u1Jsx} and {u2Jsx} are typing...
                </Typography>
            );
        }

        const u3Jsx = getUserNameJsx(typingMembers[2]);
        if (typingMembers.length === 3) {
            return (
                <Typography color="textSecondary">
                    {u1Jsx}, {u2Jsx} and {u3Jsx} are typing...
                </Typography>
            );
        }

        const othersCount = typingMembers.length - MAX_VISIBLE_COUNT;
        return (
            <Typography color="textSecondary">
                {u1Jsx}, {u2Jsx}, {u3Jsx} and {othersCount} other{othersCount === 1 ? '' : 's'} are typing...
            </Typography>
        );
    }, [typingMembers]);

    return <Box className={classes.root}>{getTypingMessage()}</Box>;
};
