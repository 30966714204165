import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        maxWidth: 400,
        position: 'relative',
        overflow: 'hidden',
    },
    timeout: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    iconContainer: {
        marginRight: 12,
        width: 40,
        height: 40,
        minWidth: 40,
        minHeight: 40,
        display: 'flex',
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
    },
    success: {
        color: 'rgb(84, 214, 44)',
        backgroundColor: 'rgba(84, 214, 44, 0.16)',
    },
    error: {
        color: 'rgb(255, 72, 66)',
        backgroundColor: 'rgba(255, 72, 66, 0.16)',
    },
    info: {
        color: 'rgb(24, 144, 255)',
        backgroundColor: 'rgba(24, 144, 255, 0.16)',
    },
    warning: {
        color: 'rgb(255, 193, 7)',
        backgroundColor: 'rgba(255, 193, 7, 0.16)',
    },
}));
