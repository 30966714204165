import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useStyles } from './security-section-item.style';

type SecuritySectionItemProps = {
    title: string;
    content: React.ReactNode;
};

export const SecuritySectionItem: React.FC<SecuritySectionItemProps> = ({ title, content }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>{title}</Typography>
            <Divider />
            <Box className={classes.content}>{content}</Box>
        </Box>
    );
};
