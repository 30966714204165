import { Box, Typography } from '@material-ui/core';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import { MatrixEventType } from 'chat/context/matrix/types';
import { useChat } from 'chat/context/store';
import { EventTimeline, MatrixEvent, Room, RoomMember } from 'matrix-js-sdk';
import React, { useCallback, useEffect } from 'react';
import { MessageInput, RoomHeader } from './components';
import { InviteUserDialog } from './components/invite-user-dialog';
import { TimelinePanel } from './components/timeline-panel';
import { TypingPanel } from './components/typing-panel';
import { RoomInviteView } from './room-invite-view';
import { useStyles } from './room-view.style';

export const RoomView: React.FC = () => {
    const classes = useStyles();
    const [{ activeRoomId, inviteRoomIds }, dispatch] = useChat();

    const _onTimelineEvent = useCallback(
        (
            _: MatrixEvent,
            room: Room,
            toStartOfTimeline: boolean,
            removed: boolean,
            data: {
                timeline: EventTimeline;
                liveEvent?: boolean;
            },
        ): void => {
            dispatch({ type: 'TIMELINE_EVENT', room, removed, toStartOfTimeline, liveEvent: data.liveEvent });
        },
        [dispatch],
    );

    const _onDecryptEvent = useCallback(
        (event: MatrixEvent): void => {
            dispatch({ type: 'DECRYPT_EVENT', event });
        },
        [dispatch],
    );

    const _onTypingEvent = useCallback(
        (_: MatrixEvent, member: RoomMember): void => {
            dispatch({ type: 'ROOM_MEMBER_TYPING', member });
        },
        [dispatch],
    );

    useEffect(() => {
        if (activeRoomId) {
            matrixClient.on(MatrixEventType.RoomTimeline, _onTimelineEvent);
            matrixClient.on(MatrixEventType.EventDecrypted, _onDecryptEvent);
            matrixClient.on(MatrixEventType.RoomMemberTyping, _onTypingEvent);
        }

        return () => {
            matrixClient.removeListener(MatrixEventType.RoomTimeline, _onTimelineEvent);
            matrixClient.removeListener(MatrixEventType.EventDecrypted, _onDecryptEvent);
            matrixClient.removeListener(MatrixEventType.RoomMemberTyping, _onTypingEvent);
        };
    }, [_onDecryptEvent, _onTimelineEvent, _onTypingEvent, activeRoomId]);

    if (!activeRoomId) {
        return (
            <Box display="flex" flex="1" alignItems="center" justifyContent="center">
                <Box className={classes.emptyTextPlaceholder}>
                    <Typography color="textSecondary">Select a chat to start messaging</Typography>
                </Box>
            </Box>
        );
    }

    const isInviteRoom = inviteRoomIds.includes(activeRoomId);

    if (isInviteRoom) {
        return <RoomInviteView roomId={activeRoomId} />;
    }

    return (
        <div className={classes.root} id="room-view-container">
            <RoomHeader roomId={activeRoomId} />
            <TimelinePanel key={activeRoomId} />
            <Box display="flex" flexDirection="column">
                <TypingPanel />
                <MessageInput key={activeRoomId} roomId={activeRoomId} />
            </Box>
            <InviteUserDialog roomId={activeRoomId} />
        </div>
    );
};
