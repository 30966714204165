import { Box, List, Typography } from '@material-ui/core';
import { useChat } from 'chat/context/store';
import React, { useCallback, useEffect } from 'react';
import { CollapsibleList, CreateDirectChatDialog, CreateMenu, CreateRoomDialog, RoomListItem } from './components';
import { useStyles } from './room-list.style';
import { PublicRoomsDialog } from './components/public-rooms-dialog';
import { MatrixEventType } from 'chat/context/matrix/types';
import { EventType } from 'matrix-js-sdk/lib/@types/event';
import { matrixClient } from 'chat/context/matrix/matrix-client';

const RoomListComponent: React.FC = () => {
    const classes = useStyles();
    const [{ activeRoomId, roomIds, directIds, inviteRoomIds }, dispatch] = useChat();
    const loadRooms = useCallback(() => {
        dispatch({ type: 'LOAD_ROOMS' });
    }, [dispatch]);

    useEffect(() => {
        loadRooms();
        matrixClient.on(EventType.RoomAvatar, loadRooms);
        matrixClient.on(MatrixEventType.AccountData, loadRooms);
        matrixClient.on(MatrixEventType.RoomName, loadRooms);
        matrixClient.on(MatrixEventType.RoomStateEvents, loadRooms);
        matrixClient.on(MatrixEventType.RoomMyMembership, loadRooms);
        matrixClient.on(MatrixEventType.RoomTimeline, loadRooms);
        return () => {
            matrixClient.removeListener(EventType.RoomAvatar, loadRooms);
            matrixClient.removeListener(MatrixEventType.AccountData, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomName, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomStateEvents, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomMyMembership, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomTimeline, loadRooms);
        };
    }, [loadRooms]);

    const renderRoom = (roomId: string) => {
        return <RoomListItem key={roomId} roomId={roomId} selected={roomId === activeRoomId} />;
    };

    const isInvitesEmpty = inviteRoomIds.length === 0;

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography>Conversations</Typography>
                <CreateMenu />
            </Box>
            <Box className={classes.content}>
                {!isInvitesEmpty && (
                    <CollapsibleList title="Invites">
                        <List component="nav" className={classes.list}>
                            {inviteRoomIds.map(renderRoom)}
                        </List>
                    </CollapsibleList>
                )}
                {directIds.length > 0 && (
                    <CollapsibleList title="Directs">
                        <List component="nav" className={classes.list}>
                            {directIds.map(renderRoom)}
                        </List>
                    </CollapsibleList>
                )}
                <CollapsibleList title="Rooms">
                    <List component="nav" className={classes.list}>
                        {roomIds.map(renderRoom)}
                    </List>
                </CollapsibleList>
            </Box>
            <CreateDirectChatDialog />
            <CreateRoomDialog />
            <PublicRoomsDialog />
        </Box>
    );
};

export const RoomList = React.memo(RoomListComponent);
