import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            minWidth: 200,
            '& .matrix-MuiListItemIcon-root': {
                minWidth: 'unset',
                marginRight: theme.spacing(2),
            },
        },
        leave: {
            color: '#ff5b55',
            '& svg': {
                fill: '#ff5b55',
            },
        },
    };
});
