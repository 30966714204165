import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from './security.style';
import { Formik } from 'formik';
import { RoomVisibility, Encryption, MessageHistoryVisibility } from './components';

export const Security: React.FC = () => {
    const classes = useStyles();
    return (
        <Formik
            initialValues={{
                roomVisibility: 'private',
                encryptionEnabled: false,
                messageHistoryVisibility: '0',
            }}
            onSubmit={() => {
                //
            }}
        >
            {({ setFieldValue, values }) => {
                return (
                    <Box className={classes.securitySections}>
                        <RoomVisibility setFieldValue={setFieldValue} values={values} />
                        <Encryption setFieldValue={setFieldValue} values={values} />
                        <MessageHistoryVisibility setFieldValue={setFieldValue} values={values} />
                    </Box>
                );
            }}
        </Formik>
    );
};
