import React, { useState } from 'react';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import { SettingSectionItem } from '../components';

export const Notifications: React.FC = () => {
    const [mute, setMute] = useState<boolean>(false);

    return (
        <SettingSectionItem
            onClick={() => {
                setMute((val) => !val);
            }}
            icon={mute ? <NotificationsOffOutlinedIcon /> : <NotificationsActiveOutlinedIcon />}
            title={mute ? 'Unmute' : 'Mute'}
            disabled
        />
    );
};
