import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        listContainer: {
            minWidth: 200,
            '& .matrix-MuiListItemIcon-root': {
                minWidth: 'unset',
                marginRight: theme.spacing(2),
            },
        },
        avatarContainer: {
            minWidth: 'unset',
            marginRight: theme.spacing(2),
        },
        avatar: {
            width: 36,
            height: 36,
        },
    };
});
