import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        textField: {
            '& .matrix-MuiInputBase-root': {
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(0),
            },
            '& svg': {
                color: 'rgba(255,0,0,0.8)',
            },
        },
        loadMoreButton: {
            display: 'flex',
            margin: '0 auto',
            backgroundColor: '#0dbd8b',
            color: '#ffffff',
            '&:hover': {
                backgroundColor: '#0dbd8b',
            },
        },
    };
});
