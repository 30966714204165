import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

export type LinkProps = React.PropsWithoutRef<ReactRouterLinkProps> & { link?: boolean };

const useStyles = makeStyles(() => {
    return {
        root: {
            textDecoration: 'none !important',
            color: 'unset',
        },
        underline: {
            '&:hover': {
                textDecoration: 'underline !important',
            },
        },
    };
});

export const Link: React.FC<LinkProps> = ({ to, link, children, style, className, ...props }) => {
    const classes = useStyles();
    return (
        <ReactRouterLink
            className={clsx(classes.root, link && classes.underline, className)}
            to={to}
            style={{ ...style }}
            {...props}
        >
            {children}
        </ReactRouterLink>
    );
};
