import { useChatDispatch } from 'chat/context/store';
import { FormikValues, useFormikContext } from 'formik';
import { throttle, debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

export const TypingHandler: React.FC = () => {
    const dispatch = useChatDispatch();
    const { values } = useFormikContext<FormikValues>();

    const sendTyping = useCallback(
        (roomId: string, isTyping: boolean) => {
            dispatch({ type: 'SEND_TYPING', roomId, isTyping });
        },
        [dispatch],
    );

    const sendTypingThrottled = useMemo(() => throttle(sendTyping, 5000), [sendTyping]);
    const sendNotTypingDebounced = useMemo(
        () =>
            debounce((roomId: string) => {
                sendTypingThrottled.cancel();
                sendTyping(roomId, false);
            }, 1000),
        [sendTyping, sendTypingThrottled],
    );

    useEffect(() => {
        const roomId = values.roomId;

        if (values.text && values.text.length > 0) {
            sendTypingThrottled(roomId, true);
            sendNotTypingDebounced(roomId);
        } else {
            sendTypingThrottled.cancel();
            sendTyping(roomId, false);
        }
    }, [sendTyping, sendNotTypingDebounced, sendTypingThrottled, values]);

    useEffect(() => {
        return () => {
            sendTypingThrottled.cancel();
            sendNotTypingDebounced.cancel();
        };
    }, [sendNotTypingDebounced, sendTypingThrottled]);

    return null;
};
