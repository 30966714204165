export const ALLOWED_BLOB_MIMETYPES = [
    'image/jpeg',
    'image/gif',
    'image/png',

    'video/mp4',
    'video/webm',
    'video/ogg',

    'audio/mp4',
    'audio/webm',
    'audio/aac',
    'audio/mpeg',
    'audio/ogg',
    'audio/wave',
    'audio/wav',
    'audio/x-wav',
    'audio/x-pn-wav',
    'audio/flac',
    'audio/x-flac',
];

export const getBlobSafeMimeType = (mimetype) => {
    if (!ALLOWED_BLOB_MIMETYPES.includes(mimetype)) {
        return 'application/octet-stream';
    }
    return mimetype;
};

export const getImageDimension = (file: File): Promise<{ w: number; h: number }> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = async () => {
            resolve({
                w: img.width,
                h: img.height,
            });
        };
        img.src = URL.createObjectURL(file);
    });
};

export const loadVideo = (videoFile: File): Promise<HTMLVideoElement> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.playsInline = true;
        video.muted = true;

        const reader = new FileReader();

        reader.onload = (ev) => {
            // Wait until we have enough data to thumbnail the first frame.
            video.onloadeddata = async () => {
                resolve(video);
                video.pause();
            };
            video.onerror = (e) => {
                reject(e);
            };

            if (ev.target && typeof ev.target.result === 'string') {
                video.src = ev.target.result;
            }

            video.load();
            video.play();
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsDataURL(videoFile);
    });
};

export const getVideoThumbnail = (
    video: HTMLVideoElement,
    width: number,
    height: number,
    mimeType: File['type'],
): Promise<{
    thumbnail: Blob;
    info: {
        w: number;
        h: number;
        mimetype: File['type'];
        size: number;
    };
} | null> => {
    return new Promise((resolve) => {
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let targetWidth = width;
        let targetHeight = height;
        if (targetHeight > MAX_HEIGHT) {
            targetWidth = Math.floor(targetWidth * (MAX_HEIGHT / targetHeight));
            targetHeight = MAX_HEIGHT;
        }
        if (targetWidth > MAX_WIDTH) {
            targetHeight = Math.floor(targetHeight * (MAX_WIDTH / targetWidth));
            targetWidth = MAX_WIDTH;
        }

        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const context = canvas.getContext('2d');
        if (context) {
            context.drawImage(video, 0, 0, targetWidth, targetHeight);
        }

        canvas.toBlob((thumbnail) => {
            if (thumbnail) {
                resolve({
                    thumbnail,
                    info: {
                        w: targetWidth,
                        h: targetHeight,
                        mimetype: thumbnail.type,
                        size: thumbnail.size,
                    },
                });
            } else {
                resolve(null);
            }
        }, mimeType);
    });
};

export const getFileUrl = async (link: string) => {
    try {
        const response = await fetch(link, { method: 'GET' });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (e) {
        return link;
    }
};

export const getNativeHeight = (width: number, height: number) => {
    const MAX_WIDTH = 296;
    const scale = MAX_WIDTH / width;
    return scale * height;
};
