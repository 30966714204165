import React from 'react';
import { Box, Typography, Divider, List } from '@material-ui/core';
import { useStyles } from './members.style';
import { RoomMember } from 'matrix-js-sdk';
import { RoomMemberItem } from '../../room-member-list/room-member-item';

type MembersProps = {
    members: RoomMember[];
    roomId: string;
};

export const Members: React.FC<MembersProps> = ({ members, roomId }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Members</Typography>
            <Divider />
            <List>
                {members.map((member) => (
                    <RoomMemberItem key={member.userId} member={member} roomId={roomId} />
                ))}
            </List>
        </Box>
    );
};
