import { Box, Button, Divider, Drawer, DrawerProps, IconButton, List, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useChatDispatch } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback } from 'react';
import { RoomMemberItem } from './room-member-item';
import { useStyles } from './room-member-list.style';

type RoomMemberListProps = DrawerProps & {
    roomId: string;
};

export const RoomMemberList: React.FC<RoomMemberListProps> = ({ roomId, onClose, ...props }) => {
    const classes = useStyles();
    const dispatch = useChatDispatch();
    const { members, name } = useRoom(roomId);

    const closeMemberList = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);
    const openInviteUserDialog = useCallback(() => dispatch({ type: 'OPEN_INVITE_USER_DIALOG' }), [dispatch]);

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            elevation={1}
            classes={{ paper: classes.root }}
            {...props}
            onClose={onClose}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" p={1.5}>
                <Typography variant="h5">{name}</Typography>
                <IconButton size="small" onClick={closeMemberList}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            <Divider />
            <Box p={1.5}>
                <Button variant="contained" fullWidth startIcon={<PersonAddIcon />} onClick={openInviteUserDialog}>
                    <Typography variant="h6">Invite to this room</Typography>
                </Button>
            </Box>
            <List>
                {members.map((member) => (
                    <RoomMemberItem key={member.userId} member={member} roomId={roomId} />
                ))}
            </List>
        </Drawer>
    );
};
