import { MatrixSetup } from 'chat/context/matrix/matrix-setup';
import { Dispatch, Reducer, useEffect, useMemo } from 'react';
import { createContainer } from 'react-tracked';
import { useReducerAsync } from 'use-reducer-async';
import { ConfigStore } from './matrix/config-store';
import { MatrixAuthData } from './matrix/types';
import { asyncActionHandlers, AsyncChatAction, ChatAction, ChatState, INITIAL_CHAT_STATE, reducer } from './reducer';

type StoreProps = {
    baseUrl: string;
    matrixAuth: MatrixAuthData;
};

const useValue = ({ baseUrl, matrixAuth }: StoreProps): [ChatState, Dispatch<ChatAction | AsyncChatAction>] => {
    const [state, dispatch] = useReducerAsync<Reducer<ChatState, ChatAction>, AsyncChatAction>(
        reducer,
        INITIAL_CHAT_STATE,
        asyncActionHandlers,
    );

    const matrixSetup = useMemo(() => new MatrixSetup(baseUrl), [baseUrl]);

    useEffect(() => {
        // TODO: Remove this later
        globalThis.state = state;
        globalThis.dispatch = dispatch;
        globalThis.matrix = matrixSetup;
    }, [state, dispatch, matrixSetup]);

    useEffect(() => {
        if (matrixAuth) {
            ConfigStore.setMatrixAuthData(matrixAuth);
            dispatch({ type: 'LOGGED_IN' });

            matrixSetup.once('init_loading_finished', () => {
                dispatch({ type: 'CHAT_LOADED' });
            });

            matrixSetup.init();
        }
    }, [dispatch, matrixSetup, matrixAuth]);

    return [state, dispatch];
};

export const {
    Provider: ChatProvider,
    useTracked: useChat,
    useTrackedState: useChatState,
    useUpdate: useChatDispatch,
    useSelector: useChatStateSelector,
} = createContainer(useValue);
