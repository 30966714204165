import { Box, Button, List, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RoomStore } from 'chat/context/matrix/room-store';
import { useChat } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import { LoadingButton, Spinner } from 'components';
import React, { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { InviteUserItem } from '../invite-user-item';
import { useStyles } from './invite-user-form.style';

type InviteUserFormProps = { roomId: string; roomName: string };

export type UserInfo = { avatar_url?: string; displayName: string; userId: string };

export const InviteUserForm: React.FC<InviteUserFormProps> = ({ roomId, roomName }) => {
    const classes = useStyles();
    const [{ inviteUserToRoomLoading, inviteUserToRoomSuccessMessage, inviteUserToRoomError }, dispatch] = useChat();

    const { members } = useRoom(roomId);

    const [userIds, setUserIds] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const isEmpty = userIds.length === 0;

    // debounce the search api call
    const [keyword, setKeyword] = useState('');

    const search = async () => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: 1 });
        const filteredUserIds = result.userIds.filter((userId) => {
            const memberIds = members.map((member) => member.userId);
            return !memberIds.includes(userId);
        });
        setUserIds(filteredUserIds);
        setHasNextPage(result.hasNextPage);
        setPage(1);
        setLoading(false);
    };

    const loadMoreUsers = async () => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: page + 1 });
        setUserIds((prev) => [...prev, ...result.userIds]);
        setHasNextPage(result.hasNextPage);
        setPage((prev) => prev + 1);
        setLoading(false);
    };

    useDebounce(
        () => {
            setUserIds([]);
            search();
        },
        500,
        [keyword],
    );

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

    const clearSelectedUser = useCallback(() => setSelectedUserIds([]), []);

    const clickUser = (user: UserInfo) => {
        if (!selectedUserIds?.includes(user.userId)) {
            setSelectedUserIds((values) => (values ? [...values, user.userId] : [user.userId]));
        } else {
            const filteredSelectedUserIds = selectedUserIds.filter((selectedUserId) => selectedUserId !== user.userId);
            setSelectedUserIds(filteredSelectedUserIds);
        }
    };

    const onSubmit = () => {
        if (selectedUserIds) {
            dispatch({
                type: 'INVITE_USER_TO_ROOM',
                roomId,
                userIds: selectedUserIds,
                successMessage: `Invited to ${roomName} successfully!`,
            });
            clearSelectedUser();
        }
    };

    return (
        <Box>
            <Box display="flex">
                <Box flexGrow={1} mr={2}>
                    <TextField
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search for user..."
                        variant="outlined"
                        multiline
                        fullWidth
                        className={classes.field}
                    />
                </Box>
                <LoadingButton
                    disabled={inviteUserToRoomLoading}
                    onClick={onSubmit}
                    className={classes.button}
                    loading={inviteUserToRoomLoading}
                    text="Invite"
                    loadingText="Inviting"
                    variant="contained"
                    color="primary"
                />
            </Box>
            {!!inviteUserToRoomSuccessMessage && (
                <Box mt={2}>
                    <Alert severity="success">{inviteUserToRoomSuccessMessage}</Alert>
                </Box>
            )}
            {!!inviteUserToRoomError && (
                <Box mt={2}>
                    <Alert severity="error">{inviteUserToRoomError}</Alert>
                </Box>
            )}

            <Box mt={2}>
                {!isEmpty && (
                    <List>
                        {userIds.map((id) => {
                            return (
                                <InviteUserItem
                                    key={id}
                                    userId={id}
                                    onClick={clickUser}
                                    selected={selectedUserIds?.includes(id)}
                                />
                            );
                        })}
                    </List>
                )}
                {isEmpty && !loading && (
                    <Box height={100} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="h6">No results</Typography>
                    </Box>
                )}
                {loading && <Spinner style={{ height: 100 }} />}
                {!isEmpty && hasNextPage && !loading && (
                    <Button fullWidth onClick={loadMoreUsers}>
                        Load more users
                    </Button>
                )}
            </Box>
        </Box>
    );
};
