import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import { NotificationsProvider } from 'context/NotificationsContext';
import { SettingsProvider } from 'context/SettingsContext';
import React, { useEffect } from 'react';
import customTheme, { PaletteType } from '../theme';
import { ChatView } from './chat-view';
import { GetInviteListFn, RoomStore } from './context/matrix/room-store';

export type ChatProps = {
    getInviteList: GetInviteListFn;
    themeType?: PaletteType;
};

export const Chat: React.FC<ChatProps> = ({ themeType, getInviteList }) => {
    const theme = customTheme(themeType || 'light');

    const generateClassName = createGenerateClassName({ productionPrefix: 'jss-matrix', seed: 'matrix' });

    useEffect(() => {
        if (getInviteList) {
            RoomStore.getInviteList = getInviteList;
        }
    }, [getInviteList]);

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <SettingsProvider>
                    <NotificationsProvider>
                        <ChatView />
                    </NotificationsProvider>
                </SettingsProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};
