import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import React from 'react';
import { CreateRoomValidationSchema } from './validation-schema';

export type CreateRoomFormValues = {
    name: string;
    topic?: string;
    isPublic?: boolean;
    isEncrypted?: boolean;
};

type CreateRoomFormProps = {
    initialValues: CreateRoomFormValues;
    formRef: React.RefObject<FormikProps<CreateRoomFormValues>>;
    onSubmit: (options: CreateRoomFormValues) => void;
    error: string | undefined;
};

export const CreateRoomForm: React.FC<CreateRoomFormProps> = ({ formRef, initialValues, onSubmit, error }) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formRef}
            validationSchema={CreateRoomValidationSchema}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field component={TextField} name="name" label="Room name" variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                name="topic"
                                label="Topic"
                                variant="outlined"
                                multiline
                                minRows={5}
                                maxRows={7}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h5">Make room public</Typography>
                                <Field component={Switch} name="isPublic" color="primary" type="checkbox" />
                            </Box>
                            <Box pr={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {values.isPublic
                                        ? 'Anyone will be able to find and join this room. You can change this at any time from room settings.'
                                        : 'Only people invited will be able to find and join this room. You can change this at any time from room settings.'}
                                </Typography>
                            </Box>
                        </Grid>
                        {!!error && (
                            <Grid item xs={12}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
