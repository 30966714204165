import { alpha, darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 1.5),
            minHeight: 42,
            color: alpha(theme.palette.text.primary, 0.7),
            '& svg': {
                fill: alpha(theme.palette.text.primary, 0.7),
            },
        },
        name: {
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        fileContainer: {
            borderRadius: 4,
            maxWidth: 296,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.1)
                : darken(theme.palette.background.paper, 0.05),
            overflow: 'hidden',
        },
        fileContent: {
            fontSize: 0,
            lineHeight: 0,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',

            '& img, & video, & audio': {
                width: '100%',
            },
        },
        iconButton: {},
    };
});
