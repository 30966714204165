import React, { useContext, createContext, ReactNode } from 'react';
import { Snackbar } from 'components/snackbar';
import { SnackbarCloseReason } from '@material-ui/core';
import { SnackbarType } from 'components/snackbar/snackbar';
import { ConfirmationDialog } from 'components';

type notifyType = {
    duration?: number | null;
    message: string;
    type: SnackbarType;
};

type confirmType = {
    title?: string;
    confirmText?: string;
    onConfirm: any;
};

type NotificationsContextProps = {
    notify: ({ duration, message, type }: notifyType) => void;
    close: () => void;
    confirm: ({ title, confirmText, onConfirm }: confirmType) => void;
};

const NotificationsContext = createContext<NotificationsContextProps>({
    notify: () => {
        //
    },
    close: () => {
        //
    },
    confirm: () => {
        //
    },
});
export const useNotifications = (): NotificationsContextProps =>
    useContext<NotificationsContextProps>(NotificationsContext);

const DEFAULT_SNACK_STATE = {
    open: false,
    autoHideDuration: 6000,
    message: '',
    type: 'success' as SnackbarType,
};

const DEFAULT_CONFIRM_STATE = {
    open: false,
    title: 'Are you sure?',
    confirmText: '',
    onConfirm: (): void => {
        //
    },
};

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [snack, setSnackState] = React.useState(DEFAULT_SNACK_STATE);
    const [confirmProp, setConfirmProps] = React.useState(DEFAULT_CONFIRM_STATE);

    const notify = ({ duration, message, type }: notifyType): void => {
        const newState = {
            open: true,
            autoHideDuration: duration || 6000,
            type: type || 'info',
            message,
        };
        setSnackState(newState);
    };

    const close = (): void =>
        setSnackState((prev) => ({ ...DEFAULT_SNACK_STATE, type: prev.type, message: prev.message }));

    const onClose = (_?: any, reason?: SnackbarCloseReason): void => {
        if (reason === 'clickaway') {
            return;
        }

        close();
    };

    const confirm = ({ title, confirmText, onConfirm }: confirmType): void => {
        const newState = {
            open: true,
            title: title || DEFAULT_CONFIRM_STATE.title,
            confirmText: confirmText || DEFAULT_CONFIRM_STATE.confirmText,
            onConfirm: (): void => {
                setConfirmProps(DEFAULT_CONFIRM_STATE);
                onConfirm();
            },
        };
        setConfirmProps(newState);
    };

    const onDialogClose = (): void => {
        setConfirmProps(DEFAULT_CONFIRM_STATE);
    };

    return (
        <NotificationsContext.Provider value={{ notify, confirm, close }}>
            {children}
            <ConfirmationDialog {...confirmProp} onClose={onDialogClose} />
            <Snackbar {...snack} onClose={onClose} />
        </NotificationsContext.Provider>
    );
};
