import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        minHeight: 60,
        height: 60,
        [theme.breakpoints.down(700)]: {
            paddingLeft: theme.spacing(0.5),
        },
    },
    mobileButton: {
        padding: theme.spacing(0.5),
        borderRadius: '50px',
        [theme.breakpoints.up(700)]: {
            display: 'none',
        },
    },
    avatar: {
        display: 'flex',
        flex: 0,
        [theme.breakpoints.down(700)]: {
            display: 'none',
        },
    },
    roomOptionsButton: {
        width: 'fit-content',
        padding: theme.spacing(0.25, 1),
        borderRadius: 8,
    },
}));
