import { green, red } from '@material-ui/core/colors';
import { createTheme, darken, Theme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createSpacing from '@material-ui/core/styles/createSpacing';
import { Shadows } from '@material-ui/core/styles/shadows';
import { Theme as ThemeEnum } from 'enum';

export type PaletteType = 'light' | 'dark';

const breakpoints = createBreakpoints({});
const spacing = createSpacing(8);

const shadows: Shadows = [
    'none',
    'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 3px 1px',
    'rgba(0, 0, 0, 0.2) 0px 1px 4px 1px',
    'rgba(0, 0, 0, 0.2) 0px 1px 5px 1px',
    'rgba(0, 0, 0, 0.2) 0px 1px 6px 2px',
    'rgba(0, 0, 0, 0.2) 0px 1px 7px 2px',
    'rgba(0, 0, 0, 0.2) 0px 1px 8px 2px',
    'rgba(0, 0, 0, 0.2) 0px 1px 9px 2px',
    'rgba(0, 0, 0, 0.2) 0px 1px 10px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 11px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 12px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 13px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 14px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 16px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 17px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 18px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 19px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 20px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 21px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 22px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 23px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 24px 0px',
    'rgba(0, 0, 0, 0.2) 0px 1px 25px 0px',
];

export const backgroundDark = '#000';
export const backgroundLight = '#fafafa';
export const primaryDark = '#BEAC83';
export const primaryLight = darken('#BEAC83', 0.5);

const theme = (type: PaletteType): Theme => {
    const isDarkMode = type === ThemeEnum.Dark;
    const primaryMain = isDarkMode ? primaryDark : primaryLight;
    const backgroundDefault = isDarkMode ? backgroundDark : backgroundLight;
    const backgroundPaper = isDarkMode ? '#100F11' : '#fff';
    return createTheme({
        palette: {
            type,
            background: {
                default: backgroundDefault,
                paper: backgroundPaper,
            },
            primary: {
                main: primaryMain,
            },
            error: {
                main: red.A400,
            },
            success: { light: green[600], main: green[500], dark: green[400], contrastText: '#fff' },
            text: {
                secondary: isDarkMode ? '#4D4D4D' : '#777',
            },
        },
        typography: {
            fontFamily: ['Inter', '"IBM Plex Serif"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
            overline: {
                fontSize: '1rem',
                fontWeight: 600,
                letterSpacing: 0.7,
            },
            subtitle2: {
                fontSize: '0.75rem',
                fontWeight: 600,
            },
            subtitle1: {
                fontSize: '0.875rem',
                fontWeight: 600,
            },
            body2: {
                fontSize: '0.75rem',
                fontWeight: 500,
            },
            body1: {
                fontSize: '0.875rem',
                fontWeight: 500,
            },
            h1: {
                fontSize: '1.875rem',
                fontWeight: 600,
            },
            h2: {
                fontSize: '1.625rem',
                fontWeight: 600,
            },
            h3: {
                fontSize: '1.375rem',
                fontWeight: 600,
            },
            h4: {
                fontSize: '1.25rem',
                fontWeight: 600,
            },
            h5: {
                fontSize: '1rem',
                fontWeight: 600,
            },
            h6: {
                fontSize: '0.875rem',
                fontWeight: 600,
            },
        },
        shadows,
        shape: {
            borderRadius: 8,
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        height: '100%',
                        colorScheme: type,
                        fontSize: 16,
                        [breakpoints.down('xs')]: {
                            fontSize: 14,
                        },
                        '& .matrix-MuiTouchRipple-root': {
                            display: 'none',
                        },
                    },
                    body: {
                        backgroundColor: backgroundDefault,
                    },
                },
            },
            MuiSelect: {
                select: {
                    '&:focus': {
                        borderRadius: 8,
                    },
                },
            },
            MuiButton: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                    boxShadow: 'none !important',
                },
                outlined: {
                    padding: spacing(0.6125, 3),
                },
            },
            MuiIconButton: {
                root: {
                    borderRadius: 8,
                },
                sizeSmall: {
                    padding: spacing(0.75),
                },
            },
            MuiDialogTitle: {
                root: {
                    fontSize: '1.25rem',
                    fontWeight: 600,
                },
            },
            MuiDialogContent: {
                root: {
                    padding: spacing(3),
                },
            },
            MuiDialogActions: {
                root: {
                    padding: spacing(2, 3),
                },
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: isDarkMode ? 'rgba(11, 11, 11, 0.8)' : 'rgba(244, 244, 244, 0.8)',
                },
            },
            MuiFormHelperText: {
                root: {
                    fontWeight: 500,
                },
            },
            MuiFormLabel: {
                root: {
                    fontWeight: 500,
                },
            },
        },
    });
};

export default theme;
