import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React, { useEffect, useState } from 'react';
import { useStyles } from './media.style';
import { getBlobSafeMimeType, getFileUrl, getNativeHeight } from 'chat/context/matrix/utils/file-helper';
import { Spinner } from 'components';

type BaseMediaProps = {
    name: string;
    link: string;
};

type FileHeaderProps = BaseMediaProps & {
    external?: boolean;
};

type ImageProps = BaseMediaProps & { width: number; height: number };
type AudioProps = BaseMediaProps & { type: File['type'] };
type VideoProps = BaseMediaProps & {
    thumbnail: string | null;
    width: number;
    height: number;
    type: File['type'];
};

const FileHeader: React.FC<FileHeaderProps> = ({ name, link, external }) => {
    const classes = useStyles();
    const [url, setUrl] = useState<string | null>(null);

    const handleDownload = async () => {
        let fileURL = url;
        if (!url) {
            fileURL = await getFileUrl(link);
            setUrl(fileURL);
        }
        if (fileURL) {
            const anchor = document.createElement('a');
            anchor.download = name;
            anchor.href = fileURL;
            anchor.click();
            anchor.remove();
        }
    };

    return (
        <div className={classes.header}>
            <Typography className={classes.name} variant="caption">
                {name}
            </Typography>
            {link !== null && (
                <>
                    {external && (
                        <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => window.open(url || link)}
                        >
                            <OpenInNewIcon fontSize="small" />
                        </IconButton>
                    )}
                    <IconButton className={classes.iconButton} size="small" onClick={handleDownload}>
                        <GetAppIcon fontSize="small" />
                    </IconButton>
                </>
            )}
        </div>
    );
};

const File: React.FC<BaseMediaProps> = ({ name, link }) => {
    const classes = useStyles();
    return (
        <div className={classes.fileContainer}>
            <FileHeader name={name} link={link} />
        </div>
    );
};

const Image: React.FC<ImageProps> = ({ name, width, height, link }) => {
    const classes = useStyles();

    const [url, setUrl] = useState<string | null>(null);

    useEffect(() => {
        let unmounted = false;
        async function fetchUrl() {
            const myUrl = await getFileUrl(link);
            if (unmounted) return;
            setUrl(myUrl);
        }
        fetchUrl();
        return () => {
            unmounted = true;
        };
    }, []);

    return (
        <div className={classes.fileContainer}>
            <FileHeader name={name} link={url || link} external />
            <div
                style={{ height: width !== null ? getNativeHeight(width, height) : 'unset' }}
                className={classes.fileContent}
            >
                {url !== null && <img src={url || link} alt={name} />}
            </div>
        </div>
    );
};

const Audio: React.FC<AudioProps> = ({ name, link, type }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState<string | null>(null);

    const loadAudio = async () => {
        const myUrl = await getFileUrl(link);
        setUrl(myUrl);
        setIsLoading(false);
    };

    const handlePlayAudio = () => {
        setIsLoading(true);
        loadAudio();
    };

    return (
        <div className={classes.fileContainer}>
            <FileHeader name={name} link={url || link} external />
            <div className={classes.fileContent}>
                {url === null && isLoading && <Spinner style={{ height: 40 }} size="small" />}
                {url === null && !isLoading && (
                    <Box py={1}>
                        <IconButton className={classes.iconButton} onClick={handlePlayAudio}>
                            <PlayCircleOutlineIcon />
                        </IconButton>
                    </Box>
                )}
                {url !== null && (
                    <audio autoPlay controls>
                        <source src={url} type={getBlobSafeMimeType(type)} />
                    </audio>
                )}
            </div>
        </div>
    );
};

const Video: React.FC<VideoProps> = ({ name, link, thumbnail, width, height, type }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState<string | null>(null);
    const [thumbUrl, setThumbUrl] = useState<string | null>(null);

    useEffect(() => {
        let unmounted = false;
        async function fetchUrl() {
            if (thumbnail !== null) {
                const myThumbUrl = await getFileUrl(thumbnail);
                if (unmounted) return;
                setThumbUrl(myThumbUrl);
            }
        }
        fetchUrl();
        return () => {
            unmounted = true;
        };
    }, []);

    async function loadVideo() {
        const myUrl = await getFileUrl(link);
        setUrl(myUrl);
        setIsLoading(false);
    }

    function handlePlayVideo() {
        setIsLoading(true);
        loadVideo();
    }

    return (
        <div className={classes.fileContainer}>
            <FileHeader name={name} link={url || link} external />
            <div
                style={{
                    height: width !== null ? getNativeHeight(width, height) : 'unset',
                    backgroundImage: thumbUrl === null ? 'none' : `url(${thumbUrl}`,
                }}
                className={classes.fileContent}
            >
                {url === null && isLoading && <Spinner style={{ height: 40 }} size="small" />}
                {url === null && !isLoading && (
                    <Paper>
                        <IconButton className={classes.iconButton} size="small" onClick={handlePlayVideo}>
                            <PlayCircleOutlineIcon />
                        </IconButton>
                    </Paper>
                )}
                {url !== null && (
                    <video autoPlay controls poster={thumbUrl || undefined}>
                        <source src={url} type={getBlobSafeMimeType(type)} />
                    </video>
                )}
            </div>
        </div>
    );
};

export { File, Image, Audio, Video };
