import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { useChat } from 'chat/context/store';
import clsx from 'clsx';
import { useNotifications } from 'context/NotificationsContext';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Attachment } from './attachment';
import { useStyles } from './message-input.style';
import { TypingHandler } from './typing-handler';
import validationSchema from './validation-schema';

const isMobile = (): boolean => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

type MessageInputProps = {
    roomId: string;
};

export const MessageInput: React.FC<MessageInputProps> = ({ roomId }) => {
    const { notify } = useNotifications();
    const [{ isRoomEncrypted, attachmentProgress }, dispatch] = useChat();

    const attachmentProgressPercentage =
        attachmentProgress.uploading && attachmentProgress.loadedBytes && attachmentProgress.totalBytes
            ? Math.round((attachmentProgress.loadedBytes * 100) / attachmentProgress.totalBytes)
            : undefined;

    const classes = useStyles({ attachmentProgressPercentage })();

    const inputRef = useRef<HTMLInputElement>(null);
    const attachmentInputRef = useRef<HTMLInputElement>(null);

    const openAttachmentSelector = useCallback(() => {
        if (attachmentInputRef.current) {
            attachmentInputRef.current.value = '';
            attachmentInputRef.current.click();
        }
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const initialValues: { roomId: string; text: string; attachment: File | undefined } = {
        roomId,
        text: '',
        attachment: undefined,
    };

    type FormValues = typeof initialValues;

    const onSubmit = useCallback(
        (values: FormValues, { resetForm, setSubmitting }: FormikHelpers<FormValues>): void => {
            if (!values.text && !values.attachment) {
                resetForm();
                inputRef.current?.focus();
                return;
            }

            setSubmitting(true);

            dispatch({
                type: 'SEND_MESSAGE',
                roomId: values.roomId,
                text: values.text,
                attachment: values.attachment,
                onSuccess: () => {
                    setSubmitting(false);
                    inputRef.current?.focus();
                },
                onError: (error: Error) => {
                    notify({ type: 'error', message: error?.message });
                    setSubmitting(false);
                    inputRef.current?.focus();
                },
            });
            resetForm();
        },
        [dispatch, notify],
    );

    const handleKeyDown = useCallback((e, handleSubmit): void => {
        if (isMobile()) {
            if (e.keyCode === 13 && e.shiftKey && inputRef.current) {
                if (inputRef.current) {
                    inputRef.current.style.height = 'auto';
                }
                e.preventDefault();
                handleSubmit();
            }
            if (e.which === 13) {
                return;
            }
        } else {
            if (e.keyCode === 13 && e.shiftKey && inputRef.current) {
                return;
            }
            if (e.which === 13) {
                if (inputRef.current) {
                    inputRef.current.style.height = 'auto';
                }
                e.preventDefault();
                handleSubmit();
            }
        }
    }, []);

    const messagePlaceholder = `Send ${isRoomEncrypted ? 'an encrypted' : 'a'} message...`;

    return (
        <Box className={classes.root}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ handleSubmit, values, setFieldValue, isSubmitting }): ReactNode => {
                    return (
                        <Box display="flex" flex="1" alignItems="center">
                            <TypingHandler />
                            <Form style={{ width: '100%' }} onSubmit={handleSubmit} noValidate>
                                {values.attachment && (
                                    <Box display="flex" alignItems="center" mb={1}>
                                        <IconButton
                                            onClick={() => setFieldValue('attachment', undefined)}
                                            size="small"
                                            className={classes.iconButton}
                                            disabled={isSubmitting}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Box ml={1}>
                                            <Attachment attachment={values.attachment} />
                                        </Box>
                                    </Box>
                                )}
                                <Box display="flex" alignItems="center">
                                    <Box mr={1}>
                                        <IconButton
                                            onClick={openAttachmentSelector}
                                            size="small"
                                            className={clsx(
                                                classes.iconButton,
                                                values.attachment && classes.iconButtonHidden,
                                            )}
                                            disabled={isSubmitting}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                    <Box flexGrow={1} className={classes.container}>
                                        <Field
                                            autoFocus
                                            component={TextField}
                                            fullWidth
                                            name="text"
                                            multiline
                                            minRows={1}
                                            maxRows={3}
                                            placeholder={messagePlaceholder}
                                            autoComplete="off"
                                            onKeyDown={(event): void => handleKeyDown(event, handleSubmit)}
                                            inputRef={inputRef}
                                            className={classes.input}
                                            disabled={false}
                                        />
                                    </Box>
                                    <Box ml={1}>
                                        <IconButton
                                            type="submit"
                                            size="small"
                                            className={clsx(classes.iconButton, classes.sendButton)}
                                            disabled={(!values.text && !values.attachment) || isSubmitting}
                                        >
                                            <SendRoundedIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <input
                                    type="file"
                                    hidden
                                    ref={attachmentInputRef}
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setFieldValue('attachment', e.target.files[0]);
                                        }
                                    }}
                                />
                            </Form>
                        </Box>
                    );
                }}
            </Formik>
        </Box>
    );
};
