import { MatrixClient, MatrixEvent } from 'matrix-js-sdk';

export type EventEdits = { [eventId: string]: MatrixEvent[] };
export type LoadTimelinePayload = { events: MatrixEvent[]; eventEdits: EventEdits; canPaginate: boolean };

export let matrixClient: MatrixClient;

export const initMatrix = (client: MatrixClient): void => {
    matrixClient = client;
};
