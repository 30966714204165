import { Avatar, Box, Button, Typography, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useChatDispatch } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback, useState } from 'react';
import { OptionsMenu } from '../options-menu';
import { useStyles } from './room-header.style';

type RoomHeaderProps = {
    roomId: string;
};

export const RoomHeader: React.FC<RoomHeaderProps> = ({ roomId }) => {
    const theme = useTheme();
    const classes = useStyles();

    const dispatch = useChatDispatch();

    const [optionsAnchor, setOptionsAnchor] = useState<HTMLButtonElement | null>(null);

    const openOptions = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOptionsAnchor(e.currentTarget);
    }, []);
    const closeOptions = useCallback(() => setOptionsAnchor(null), []);

    const optionsOpen = !!optionsAnchor;

    const closeActiveRoom = useCallback((): void => {
        dispatch({ type: 'ROOM_CLOSED' });
    }, [dispatch]);

    const { name, avatarUrl, avatarLetter, color, topic } = useRoom(roomId);

    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    return (
        <>
            <Box className={classes.root}>
                <Button
                    className={classes.mobileButton}
                    onClick={(e): void => {
                        e.preventDefault();
                        closeActiveRoom();
                    }}
                >
                    <ArrowBackIcon style={{ marginRight: '12px' }} />
                    <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle}>
                        {avatarLetter}
                    </Avatar>
                </Button>
                <Box className={classes.avatar}>
                    <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle}>
                        {avatarLetter}
                    </Avatar>
                </Box>
                <Box display="flex" flex="1" alignItems="center" pl={1} overflow="hidden">
                    <Box display="flex" flex={1} flexDirection="column" pr={3} overflow="hidden">
                        <Button
                            onClick={openOptions}
                            className={classes.roomOptionsButton}
                            endIcon={
                                <ExpandMoreIcon
                                    fontSize="small"
                                    style={{
                                        transform: optionsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: '0.2s',
                                    }}
                                />
                            }
                        >
                            <Typography variant="h4" noWrap>
                                {name}
                            </Typography>
                        </Button>
                        <Box ml={1}>
                            <Typography variant="body2" color="textSecondary" noWrap>
                                {topic}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <OptionsMenu roomId={roomId} open={optionsOpen} anchorEl={optionsAnchor} onClose={closeOptions} />
            </Box>
        </>
    );
};
