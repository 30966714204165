import { TimelineWindow, Room, Direction, IContent, MatrixEvent, NotificationCountType } from 'matrix-js-sdk';
import { EventType, MsgType, RelationType } from 'matrix-js-sdk/lib/@types/event';
import { LoadTimelinePayload, matrixClient, EventEdits } from './matrix-client';
import { AttachmentProgressHandler } from './types';
import { getImageDimension, getVideoThumbnail, loadVideo } from './utils/file-helper';

const PAGINATE_SIZE = 20;
const INITIAL_SIZE = 20;
const SUPPORTED_EVENT_TYPES = [EventType.RoomMessage, EventType.RoomMessageEncrypted];

export class TimelineStore {
    static loadTimeline = async (
        timelineWindow: TimelineWindow | undefined,
        room?: Room,
    ): Promise<LoadTimelinePayload> => {
        if (!timelineWindow) {
            throw new Error('timelineWindow is undefined');
        }

        if (!room) {
            throw new Error('room is undefined');
        }

        await timelineWindow.load('', INITIAL_SIZE);

        if (matrixClient.isRoomEncrypted(room.roomId) && matrixClient.isCryptoEnabled()) {
            await room.decryptAllEvents();
        }

        return TimelineStore.getEvents(timelineWindow);
    };

    static paginateTimeline = async (timelineWindow: TimelineWindow | undefined): Promise<LoadTimelinePayload> => {
        if (!timelineWindow) {
            throw new Error('timelineWindow is undefined');
        }

        await timelineWindow.paginate(Direction.Backward, PAGINATE_SIZE);

        return TimelineStore.getEvents(timelineWindow);
    };

    static sendMessage = async (roomId: string, text?: string): Promise<void> => {
        if (!roomId || !text) {
            return;
        }

        const content: IContent = {
            msgtype: 'm.text',
            body: text,
        };

        await matrixClient.sendMessage(roomId, content);
    };

    static sendAttachment = async (roomId: string, file: File, progressHandler: AttachmentProgressHandler) => {
        const fileType = file.type.slice(0, file.type.indexOf('/'));
        const info: { [key: string]: any } = {
            mimetype: file.type,
            size: file.size,
        };
        const content: IContent = { info };
        let uploadData = { url: '' };

        if (fileType === 'image') {
            const imgDimension = await getImageDimension(file);

            info.w = imgDimension.w;
            info.h = imgDimension.h;

            content.msgtype = 'm.image';
            content.body = file.name || 'Image';
        } else if (fileType === 'video') {
            content.msgtype = 'm.video';
            content.body = file.name || 'Video';

            try {
                const video = await loadVideo(file);
                info.w = video.videoWidth;
                info.h = video.videoHeight;
                const thumbnailData = await getVideoThumbnail(video, video.videoWidth, video.videoHeight, 'image/jpeg');
                if (thumbnailData) {
                    const thumbnailUploadData = await this.uploadFile(thumbnailData.thumbnail);
                    info.thumbnail_info = thumbnailData.info;
                    info.thumbnail_url = thumbnailUploadData.url;
                }
            } catch (error) {
                throw new Error(error as string);
            }
        } else if (fileType === 'audio') {
            content.msgtype = 'm.audio';
            content.body = file.name || 'Audio';
        } else {
            content.msgtype = 'm.file';
            content.body = file.name || 'File';
        }

        try {
            uploadData = await this.uploadFile(file, progressHandler);
        } catch (error) {
            throw new Error(error as string);
        }
        content.url = uploadData.url;
        await matrixClient.sendMessage(roomId, content);
    };

    static uploadFile = async (file: Blob, progressHandler?: AttachmentProgressHandler) => {
        const uploadingPromise = matrixClient.uploadContent(file, {
            // don't send filename if room is encrypted.
            progressHandler,
        });

        const url = await uploadingPromise;

        return { url };
    };

    static onTimelineEvent = async (
        timelineWindow: TimelineWindow | undefined,
        removed: boolean,
    ): Promise<LoadTimelinePayload> => {
        if (!timelineWindow) {
            throw new Error('timelineWindow is undefined');
        }

        if (removed) {
            return TimelineStore.getEvents(timelineWindow);
        }

        await timelineWindow.paginate(Direction.Forward, 1, false);

        return TimelineStore.getEvents(timelineWindow);
    };

    static onDecryptEvent = async (timelineWindow: TimelineWindow | undefined): Promise<LoadTimelinePayload> => {
        if (!timelineWindow) {
            throw new Error('timelineWindow is undefined');
        }

        return TimelineStore.getEvents(timelineWindow);
    };

    static markAllAsRead = async (room: Room | undefined, timeline: { [eventId: string]: MatrixEvent }) => {
        if (!room) return;
        const readEventId = room.getEventReadUpTo(matrixClient.getUserId());
        const timelineEvents = Object.values(timeline);
        if (timelineEvents.length === 0) return;
        const latestEvent = timelineEvents[timelineEvents.length - 1];
        if (readEventId === latestEvent.getId()) return;
        await matrixClient.sendReadReceipt(latestEvent);
        room.setUnreadNotificationCount(NotificationCountType.Total, 0);
    };

    // #region Private methods
    private static getEvents = (timelineWindow: TimelineWindow): LoadTimelinePayload => {
        const events: MatrixEvent[] = [];
        const eventEdits: EventEdits = {};
        const timelineEvents = timelineWindow.getEvents();

        timelineEvents
            .filter((e) => SUPPORTED_EVENT_TYPES.includes(e.getType() as EventType))
            .forEach((event) => {
                const type = event.getType();
                const content = event.getContent();

                const supportedRoomMessageTypes = [
                    MsgType.Text.toString(),
                    MsgType.Image.toString(),
                    MsgType.Video.toString(),
                    MsgType.Audio.toString(),
                    MsgType.File.toString(),
                    MsgType.Notice.toString(),
                    'm.bad.encrypted',
                ];
                if (type === EventType.RoomMessage && supportedRoomMessageTypes.includes(content.msgtype || '')) {
                    const relation = event.getWireContent()['m.relates_to'];

                    if (relation?.rel_type === RelationType.Replace) {
                        const editedEventId = relation?.event_id;
                        const edits = eventEdits[editedEventId] || [];
                        edits.push(event);
                        eventEdits[editedEventId] = edits;
                    } else {
                        events.push(event);
                    }
                }
            });

        events.sort((a, b) => a.getTs() - b.getTs());
        const canPaginate = timelineWindow.canPaginate(Direction.Backward);

        return { events, eventEdits, canPaginate };
    };
    // #endregion
}
