import { Box, Collapse, CollapseProps, Typography, TypographyProps } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useCallback, useState } from 'react';
import { useStyles } from './collapsible-list.style';

type CollapsibleListProps = {
    title: string;
    right?: React.ReactNode;
    collapseProps?: CollapseProps;
    titleProps?: TypographyProps;
};

export const CollapsibleList: React.FC<CollapsibleListProps> = ({
    title,
    right,
    collapseProps,
    titleProps,
    children,
}) => {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);

    return (
        <Box>
            <Box display="flex" alignItems="center" px={1} height={30}>
                <Box className={classes.root} onClick={toggleCollapse}>
                    <KeyboardArrowDownIcon
                        fontSize="small"
                        style={{ transform: collapsed ? 'rotate(-90deg)' : 'rotate(0deg)', transition: '.3s' }}
                        className={classes.textSecondary}
                    />
                    <Typography variant="h6" color="textSecondary" {...titleProps}>
                        {title}
                    </Typography>
                </Box>
                {right}
            </Box>
            <Collapse in={!collapsed} {...collapseProps}>
                {children}
            </Collapse>
        </Box>
    );
};
