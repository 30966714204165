import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import { useStyles } from './header.style';

type HeaderProps = {
    dirty: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    closeMemberList: () => void | undefined;
};

export const Header: React.FC<HeaderProps> = ({ dirty, handleSubmit, closeMemberList }) => {
    const classes = useStyles();

    return (
        <Box className={classes.header}>
            <Button onClick={closeMemberList} startIcon={<ChevronLeftOutlinedIcon />} className={classes.back}>
                Back
            </Button>
            <Typography variant="h5">Settings</Typography>
            <Box style={{ width: 71.9 }}>
                {dirty && (
                    <Button className={classes.edit} onClick={() => handleSubmit()}>
                        Save
                    </Button>
                )}
            </Box>
        </Box>
    );
};
