import { INDEXED_DB_CRYPTO_STORE_NAME, INDEXED_DB_STORE_NAME } from './matrix-setup';
import { MatrixAuthData } from './types';

const MATRIX_AUTH_DATA_KEY = '@Matrix/authData';

export class ConfigStore {
    static setMatrixAuthData = (matrixAuth: MatrixAuthData): void => {
        localStorage.setItem(MATRIX_AUTH_DATA_KEY, JSON.stringify(matrixAuth));
    };

    static getMatrixAuthData = (): MatrixAuthData | null => {
        const value = localStorage.getItem(MATRIX_AUTH_DATA_KEY);
        return value ? JSON.parse(value) : null;
    };

    static clearMatrixAuthData = (): void => {
        localStorage.removeItem(MATRIX_AUTH_DATA_KEY);
        indexedDB.deleteDatabase(`matrix-js-sdk:${INDEXED_DB_STORE_NAME}`);
        indexedDB.deleteDatabase(INDEXED_DB_CRYPTO_STORE_NAME);
    };
}
