import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface SpinnerProps extends CircularProgressProps {
    title?: string;
    style?: React.CSSProperties;
    spinnerStyle?: React.CSSProperties;
    color?: 'inherit' | 'secondary' | 'primary';
    button?: boolean;
    containerClassName?: string;
    spinnerClassName?: string;
}

const useStyles = makeStyles((theme) => {
    return {
        fullPage: {
            width: '100%',
            flex: 1,
            height: `calc(var(--vh, 1vh) * 100)`,
        },
        button: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginLeft: theme.spacing(1),
            flex: 0,
        },
        buttonSpinner: {
            width: `${theme.spacing(2)}px !important`,
            height: `${theme.spacing(2)}px !important`,
        },
    };
});

export const Spinner: React.FC<SpinnerProps> = ({
    title,
    button,
    disableShrink,
    style,
    containerClassName,
    spinnerStyle,
    spinnerClassName,
    color = 'primary',
    ...props
}) => {
    const classes = useStyles();
    return (
        <Grid
            className={clsx(classes.fullPage, button && classes.button, containerClassName)}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ ...style }}
        >
            <CircularProgress
                className={clsx(button && classes.buttonSpinner, spinnerClassName)}
                style={spinnerStyle}
                disableShrink={disableShrink}
                color={button ? 'inherit' : color}
                {...props}
            />

            {title && (
                <Grid item style={{ marginTop: '5px' }}>
                    <Typography> {title}</Typography>
                </Grid>
            )}
        </Grid>
    );
};
