import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useChat } from 'chat/context/store';
import React from 'react';
import { useStyles } from './create-direct-chat-dialog.style';
import { CreateDirectChatForm } from './create-direct-chat-form';

export const CreateDirectChatDialog: React.FC = () => {
    const classes = useStyles();
    const [{ createDirectChatDialogOpen, createDirectChatLoading }, dispatch] = useChat();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const closeDialog = () => dispatch({ type: 'CLOSE_CREATE_DIRECT_CHAT_DIALOG' });

    return (
        <Dialog fullWidth maxWidth="sm" open={createDirectChatDialogOpen} fullScreen={isMobile}>
            <DialogTitle className={classes.header} disableTypography>
                <Typography component="h4" variant="h4">
                    Create a direct chat
                </Typography>
                <IconButton size="small" onClick={closeDialog} disabled={createDirectChatLoading}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CreateDirectChatForm />
            </DialogContent>
            <Divider />
        </Dialog>
    );
};
