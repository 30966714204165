import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    PopoverOrigin,
    Tooltip,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { useChatDispatch } from 'chat/context/store';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useStyles } from './create-menu.style';

export const CreateMenu = () => {
    const classes = useStyles();
    const dispatch = useChatDispatch();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const openCreateDirectChatDialog = useCallback(() => {
        dispatch({ type: 'OPEN_CREATE_DIRECT_CHAT_DIALOG' });
        closeMenu();
    }, [dispatch, closeMenu]);

    const openCreateRoomDialog = useCallback(() => {
        dispatch({ type: 'OPEN_CREATE_ROOM_DIALOG' });
        closeMenu();
    }, [dispatch, closeMenu]);

    const openPublicRoomsDialog = useCallback(() => {
        dispatch({ type: 'OPEN_PUBLIC_ROOMS_DIALOG' });
        closeMenu();
    }, [dispatch, closeMenu]);

    const anchorOrigin: PopoverOrigin = useMemo(
        () => ({
            vertical: 'bottom',
            horizontal: 'left',
        }),
        [],
    );

    const transformOrigin: PopoverOrigin = useMemo(
        () => ({
            vertical: 'top',
            horizontal: 'left',
        }),
        [],
    );

    return (
        <Fragment>
            <Tooltip title="Add" placement="right">
                <IconButton size="small" onClick={openMenu}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closeMenu}
                classes={{ paper: classes.root }}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <List disablePadding dense>
                    <ListItem button onClick={openCreateDirectChatDialog}>
                        <ListItemIcon>
                            <PersonAddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Start new chat</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                <List disablePadding dense>
                    <ListItem button onClick={openCreateRoomDialog}>
                        <ListItemIcon>
                            <PlaylistAddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Create new room</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                <List disablePadding dense>
                    <ListItem button onClick={openPublicRoomsDialog}>
                        <ListItemIcon>
                            <SearchOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Join Public Rooms</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
        </Fragment>
    );
};
