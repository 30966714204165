import {
    Avatar,
    Badge,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    useTheme,
} from '@material-ui/core';
import { useChatDispatch } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback } from 'react';
import { useStyles } from './room-list-item.style';

type RoomListItemProps = {
    roomId: string;
    selected: boolean;
};

const RoomListItemComponent: React.FC<RoomListItemProps> = ({ roomId, selected }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useChatDispatch();
    const { name, avatarLetter, avatarUrl, color, unreadNotificationsCount } = useRoom(roomId);

    const selectRoom = useCallback(() => {
        dispatch({ type: 'ROOM_SELECTED', roomId });
    }, [dispatch, roomId]);

    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    return (
        <ListItem button alignItems="flex-start" className={classes.listItem} selected={selected} onClick={selectRoom}>
            <ListItemAvatar className={classes.avatar}>
                <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle}>
                    {avatarLetter}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                classes={{ primary: !!unreadNotificationsCount ? classes.nameUnread : classes.nameRead }}
                primary={name}
                primaryTypographyProps={{ noWrap: true }}
            />
            {!!unreadNotificationsCount && (
                <ListItemSecondaryAction>
                    <Badge
                        badgeContent={unreadNotificationsCount}
                        color="error"
                        classes={{ badge: classes.chatBadge }}
                    />
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};

export const RoomListItem = React.memo(RoomListItemComponent);
