import React, { useCallback } from 'react';
import { SettingSectionItem } from '../components';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { useNotifications } from 'context/NotificationsContext';
import { useChatDispatch } from 'chat/context/store';

export const Leave: React.FC<{ roomId: string }> = ({ roomId }) => {
    const { confirm } = useNotifications();
    const dispatch = useChatDispatch();

    const leaveRoom = useCallback(
        () =>
            confirm({
                title: 'Leave room',
                confirmText: 'Are you sure you want to leave this room?',
                onConfirm: () => dispatch({ type: 'LEAVE_ROOM', roomId }),
            }),
        [dispatch, confirm, roomId],
    );

    return <SettingSectionItem onClick={leaveRoom} icon={<ExitToAppOutlinedIcon />} title="Leave" />;
};
