import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: 300,
            minWidth: 300,
            height: '100%',
            padding: 0,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down(700)]: {
                width: '100%',
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 1),
            height: '60px',
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        categoryHeader: {
            opacity: 0.6,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
            overflow: 'overlay',
            padding: theme.spacing(1, 0),
        },
        list: {
            padding: theme.spacing(1),
        },
        search: {
            '& .matrix-MuiOutlinedInput-input': {
                padding: theme.spacing(1.5, 1),
            },
        },
    };
});
