import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        addButton: {
            borderRadius: theme.spacing(1),
        },
    };
});
