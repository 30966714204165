import { useMemo, useRef } from 'react';

export const usePrependedMessagesCount = (eventIds: string[]): number => {
    const currentFirstMessageId = eventIds[0];
    const firstMessageId = useRef(currentFirstMessageId);
    const earliestMessageId = useRef(currentFirstMessageId);
    const previousNumItemsPrepended = useRef(0);

    const numItemsPrepended = useMemo(() => {
        if (!eventIds || !eventIds.length) {
            return 0;
        }
        // if no new messages were prepended, return early (same amount as before)
        if (currentFirstMessageId === earliestMessageId.current) {
            return previousNumItemsPrepended.current;
        }

        if (!firstMessageId.current) {
            firstMessageId.current = currentFirstMessageId;
        }

        earliestMessageId.current = currentFirstMessageId;
        // if new messages were prepended, find out how many
        // start with this number because there cannot be fewer prepended items than before
        for (let i = previousNumItemsPrepended.current; i < eventIds.length; i += 1) {
            if (eventIds[i] === firstMessageId.current) {
                previousNumItemsPrepended.current = i;
                return i;
            }
        }

        return 0;
        // TODO: there's a bug here, the messages prop is the same array instance (something mutates it)
        // that's why the second dependency is necessary
    }, [eventIds, eventIds.length]);

    return numItemsPrepended;
};
