import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';
import { RoomStore } from '../matrix/room-store';
import { ChatState } from '../reducer';

type CreateDirectChatStartedAction = { type: 'CREATE_DIRECT_CHAT_STARTED' };
type CreateDirectChatFailedAction = { type: 'CREATE_DIRECT_CHAT_FAILED'; error?: string };
type CreateDirectChatSuccessAction = {
    type: 'CREATE_DIRECT_CHAT_SUCCESS';
    roomId: string;
    successMessage: string;
};
type ClearCreateDirectChatSuccessMessageAction = {
    type: 'CLEAR_CREATE_DIRECT_CHAT_SUCCESS_MESSAGE';
};

export type CreateDirectChatAsyncRoutine =
    | CreateDirectChatStartedAction
    | CreateDirectChatFailedAction
    | CreateDirectChatSuccessAction
    | ClearCreateDirectChatSuccessMessageAction;

export type CreateDirectChatAsync = {
    type: 'CREATE_DIRECT_CHAT';
    userId: string;
    successMessage: string;
};

export const CreateDirectChatHandlers: AsyncActionHandlers<
    Reducer<ChatState, CreateDirectChatAsyncRoutine>,
    CreateDirectChatAsync
> = {
    CREATE_DIRECT_CHAT: ({ dispatch, signal }) => async (action) => {
        try {
            dispatch({ type: 'CREATE_DIRECT_CHAT_STARTED' });
            const roomId = await RoomStore.createDirectChat(action);
            if (!signal.aborted) {
                dispatch({ type: 'CREATE_DIRECT_CHAT_SUCCESS', roomId, successMessage: action.successMessage });
                if (action.successMessage) {
                    setTimeout(() => dispatch({ type: 'CLEAR_CREATE_DIRECT_CHAT_SUCCESS_MESSAGE' }), 5000);
                }
            }
        } catch (error) {
            if (!signal.aborted) {
                const errcode = (error as { errcode: string }).errcode;
                const errcodes = ['M_BAD_JSON', 'M_ROOM_IN_USE', 'M_INVALID_ROOM_STATE', 'M_UNSUPPORTED_ROOM_VERSION'];

                const errorMessage = errcodes.includes(errcode) ? errcode : undefined;

                dispatch({ type: 'CREATE_DIRECT_CHAT_FAILED', error: errorMessage });
            }
            console.error(error, action.type);
        }
    },
};
