import { Box, Button, List, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RoomStore } from 'chat/context/matrix/room-store';
import { useChat } from 'chat/context/store';
import { LoadingButton, Spinner } from 'components';
import React, { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { UserItem } from '../user-item';
import { useStyles } from './create-direct-chat-form.style';

export type UserInfo = { avatar_url?: string; displayName: string; userId: string };

export const CreateDirectChatForm: React.FC = () => {
    const classes = useStyles();
    const [{ createDirectChatLoading, createDirectChatSuccessMessage, createDirectChatError }, dispatch] = useChat();

    const [userIds, setUserIds] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const isEmpty = userIds.length === 0;

    // debounce the search api call
    const [keyword, setKeyword] = useState('');

    const search = async () => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: 1 });
        setUserIds(result.userIds);
        setHasNextPage(result.hasNextPage);
        setPage(1);
        setLoading(false);
    };

    const loadMoreUsers = async () => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: page + 1 });
        setUserIds((prev) => [...prev, ...result.userIds]);
        setHasNextPage(result.hasNextPage);
        setPage((prev) => prev + 1);
        setLoading(false);
    };

    useDebounce(
        () => {
            setUserIds([]);
            search();
        },
        500,
        [keyword],
    );

    const [selectedUser, setSelectedUser] = useState<UserInfo>();

    const clearSelectedUser = useCallback(() => setSelectedUser(undefined), []);

    const selectUser = (user: UserInfo) => {
        setSelectedUser(user);
    };

    const onSubmit = () => {
        if (selectedUser) {
            dispatch({
                type: 'CREATE_DIRECT_CHAT',
                userId: selectedUser.userId,
                successMessage: 'Chat successfully created.',
            });
            clearSelectedUser();
        }
    };

    return (
        <Box>
            <Box display="flex">
                <Box flexGrow={1} mr={2}>
                    <TextField
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search for user..."
                        variant="outlined"
                        multiline
                        fullWidth
                        className={classes.field}
                    />
                </Box>
                <LoadingButton
                    disabled={!selectedUser || createDirectChatLoading}
                    onClick={onSubmit}
                    className={classes.button}
                    loading={createDirectChatLoading}
                    text="Create"
                    loadingText="Creating"
                    variant="contained"
                    color="primary"
                />
            </Box>
            {!!createDirectChatSuccessMessage && (
                <Box mt={2}>
                    <Alert severity="success">{createDirectChatSuccessMessage}</Alert>
                </Box>
            )}
            {!!createDirectChatError && (
                <Box mt={2}>
                    <Alert severity="error">{createDirectChatError}</Alert>
                </Box>
            )}
            {selectedUser && (
                <Box mt={2}>
                    <UserItem userId={selectedUser.userId} onClear={clearSelectedUser} selected />
                </Box>
            )}
            <Box mt={2}>
                {!isEmpty && (
                    <List>
                        {userIds.map((id) => {
                            return <UserItem key={id} userId={id} onClick={selectUser} />;
                        })}
                    </List>
                )}
                {isEmpty && !loading && (
                    <Box height={100} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="h6">No results</Typography>
                    </Box>
                )}
                {loading && <Spinner style={{ height: 100 }} />}
                {!isEmpty && hasNextPage && !loading && (
                    <Button fullWidth onClick={loadMoreUsers}>
                        Load more users
                    </Button>
                )}
            </Box>
        </Box>
    );
};
