import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    line: {
        display: 'flex',
        flex: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    date: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        width: 'max-content',
        whiteSpace: 'nowrap',
        opacity: 0.7,
        padding: theme.spacing(0, 1.5),
    },
}));
