import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        textField: {
            '& .matrix-MuiInputBase-root, .matrix-MuiInputBase-root:hover': {
                fontSize: '1.25rem',
                fontWeight: 600,
                lineHeight: 1.235,
                '&:before': {
                    borderBottom: 0,
                },
                '&:after': {
                    borderBottom: 0,
                },
            },
        },
        avatarSection: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: theme.spacing(2.5),
            paddingTop: theme.spacing(6),
        },
        avatar: {
            width: 100,
            height: 100,
            fontSize: 32,
            marginBottom: theme.spacing(2.5),
        },
    };
});
