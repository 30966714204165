const colors = [
    '#368bd6',
    '#ac3ba8',
    '#03b381',
    '#e64f7a',
    '#ff812d',
    '#2dc2c5',
    '#5c56f5',
    '#74d12c',
    '#2196f3',
    '#4caf50',
    '#ffeb3b',
    '#ff9800',
    '#bf360c',
    '#607d8b',
    '#ff1744',
    '#cddc39',
    '#795548',
    '#009688',
    '#d500f9',
    '#e91e63',
    '#2979ff',
    '#3f51b5',
];

const hashCode = (str: string): number => {
    let hash = 0;
    let i: number;
    let chr: number;
    if (str.length === 0) {
        return hash;
    }
    for (i = 0; i < str.length; i += 1) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return Math.abs(hash);
};

export default function colorMXID(userId: string): string {
    const colorNumber = hashCode(userId) % 8;
    return colors[colorNumber];
}
