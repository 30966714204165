import {
    Box,
    IconButton,
    Slide,
    Snackbar as MUISnackbar,
    SnackbarCloseReason,
    SnackbarContent,
    Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/Close';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './snackbar.style';

const variantIcon = {
    success: <CheckCircleRoundedIcon />,
    warning: <WarningRoundedIcon />,
    error: <CancelRoundedIcon />,
    info: <InfoRoundedIcon />,
};

const variantTitle = {
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
    info: 'Info',
};

export type SnackbarType = 'success' | 'error' | 'info' | 'warning';

type SnackbarProps = {
    open?: boolean;
    autoHideDuration: number;
    onClose: (event?: React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => void;
    type: SnackbarType;
    message: string;
};

const TransitionSlide = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide ref={ref} {...props} direction="right" />;
});

export const Snackbar: React.FC<SnackbarProps> = ({ open, autoHideDuration, onClose, type, message }) => {
    const classes = useStyles();

    return (
        <MUISnackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            TransitionComponent={TransitionSlide}
        >
            <SnackbarContent
                classes={{ root: classes.root }}
                message={
                    <Box display="flex" mr={4}>
                        <Box className={clsx(classes.iconContainer, classes[type])}>{variantIcon[type]}</Box>
                        <Box display="flex" flexDirection="column" style={{ marginTop: -3 }}>
                            <Typography variant="subtitle1">{variantTitle[type]}</Typography>
                            <Typography color="textSecondary" variant="body1">
                                {message}
                            </Typography>
                        </Box>
                    </Box>
                }
                action={[
                    <IconButton key="close" size="small" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </MUISnackbar>
    );
};
