import { useMediaQuery, useTheme } from '@material-ui/core';
import { Spinner } from 'components';
import React, { useEffect } from 'react';
import { useChat } from './context/store';
import { RoomList } from './room-list';
import { RoomView } from './room-view';

export const ChatView: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(700));
    const [{ chatIsReady, activeRoomId }, dispatch] = useChat();

    useEffect(() => {
        dispatch({ type: 'SET_CHAT_OPEN', open: true });

        return (): void => {
            dispatch({ type: 'SET_CHAT_OPEN', open: false });
        };
    }, [dispatch]);

    // if (!isLoggedIn) {
    //     return null;
    // }

    if (!chatIsReady) {
        return <Spinner />;
    }

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {((isMobile && !activeRoomId) || !isMobile) && <RoomList />}
            {((isMobile && activeRoomId) || !isMobile) && <RoomView />}
        </div>
    );
};
