import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useChat } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback } from 'react';
import { InviteUserForm } from './invite-user-form';

type InviteUserDialogProps = Omit<DialogProps, 'open'> & {
    roomId: string;
};

export const InviteUserDialog: React.FC<InviteUserDialogProps> = ({ roomId, ...props }) => {
    const [{ inviteUserDialogOpen, inviteUserToRoomLoading }, dispatch] = useChat();
    const { name } = useRoom(roomId);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const closeDialog = useCallback(() => dispatch({ type: 'CLOSE_INVITE_USER_DIALOG' }), [dispatch]);

    return (
        <Dialog maxWidth="sm" fullWidth fullScreen={isMobile} {...props} open={inviteUserDialogOpen}>
            <DialogTitle disableTypography>
                <Box display="flex" justifyContent="space-between" pt={1}>
                    <Box display="flex" flexDirection="column">
                        <Typography component="h4" variant="h4" gutterBottom>
                            Invite to {name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            Invite someone using their name, email address or nickname.
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={closeDialog} disabled={inviteUserToRoomLoading}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <InviteUserForm roomId={roomId} roomName={name} />
            </DialogContent>
        </Dialog>
    );
};
