import { Avatar, Box, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { RoomMember } from 'matrix-js-sdk';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { matrixClient } from 'chat/context/matrix/matrix-client';

type SenderAvatarProps = {
    empty: boolean;
    sender: RoomMember;
};

export const SenderAvatar: React.FC<SenderAvatarProps> = React.memo(({ empty, sender }) => {
    const theme = useTheme();

    const avatarUrl = sender.getAvatarUrl(matrixClient.baseUrl, 36, 36, 'crop', false, false);
    const senderColor = useMemo(() => colorMXID(sender.userId), [sender.userId]);
    const senderName = sender.name || sender.userId;

    const avatarLetter = senderName.startsWith('@') ? senderName[1] : senderName[0];
    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: senderColor };

    if (empty) {
        return <div style={{ width: '36px' }}></div>;
    }

    return (
        <Box>
            <Avatar
                alt={senderName}
                src={avatarUrl || undefined}
                style={{ width: '36px', height: '36px', ...avatarStyle }}
            >
                {avatarLetter.toUpperCase()}
            </Avatar>
        </Box>
    );
});
