import { useChatStateSelector } from 'chat/context/store';
import { NotificationCountType } from 'matrix-js-sdk';

export const useNotificationsCount = (): number => {
    const count = useChatStateSelector((s) =>
        Object.values(s.allRooms).reduce((prevRoom, currentRoom) => {
            const roomUnreadNotificationsCount = currentRoom?.getUnreadNotificationCount(NotificationCountType.Total);
            return roomUnreadNotificationsCount ? roomUnreadNotificationsCount + prevRoom : prevRoom;
        }, 0),
    );
    return count;
};
