import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        dialogPaper: {
            minWidth: 550,
            border: '1px solid rgba(255,255,255,0.05)',
            [theme.breakpoints.down('xs')]: {
                minWidth: '95vw',
                width: '95vw',
            },
        },
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1.5, 2),
        },
        content: {
            padding: theme.spacing(1, 2),
        },
        confirmText: {
            fontSize: 14,
        },
        actions: {
            padding: theme.spacing(2),
        },
        button: {
            marginLeft: theme.spacing(1.5),
        },
    };
});
