import { Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from './setting-section.style';
import { Invite, Notifications, Security, Leave } from './components';

type SettingSectionProps = {
    setActiveTab: (value: React.SetStateAction<string>) => void;
    roomId: string;
};

export const SettingSection: React.FC<SettingSectionProps> = ({ setActiveTab, roomId }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} className={classes.root}>
            <Invite />
            <Notifications />
            <Security setActiveTab={setActiveTab} />
            <Leave roomId={roomId} />
        </Grid>
    );
};
