import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            minWidth: 300,
            maxWidth: 350,
            overflowX: 'hidden',
            [theme.breakpoints.down(400)]: {
                minWidth: '100%',
            },
        },
    };
});
