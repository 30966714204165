import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    contentContainer: {
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
    },
    virtuoso: {
        display: 'flex',
        flex: 1,
        overflowX: 'hidden',
    },
    listItem: {
        display: 'inline-block',
        width: '100%',
    },
}));
