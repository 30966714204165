import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@material-ui/core';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import React, { useEffect, useState } from 'react';
import { UserInfo } from '../invite-user-form';
import { useStyles } from './invite-user-item.style';

type InviteUserItemProps = {
    userId: string;
    selected?: boolean;
    onClear?: () => void;
    onClick?: (user: UserInfo) => void;
};

export const InviteUserItem: React.FC<InviteUserItemProps> = ({ userId, selected, onClick, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const userColor = colorMXID(userId);

    const [user, setUser] = useState<{ displayname?: string; avatar_url?: string } | null>(null);

    const getUserInfo = async (userId: string) => {
        const userInfo = await matrixClient.getProfileInfo(userId);
        setUser(userInfo);
    };

    useEffect(() => {
        getUserInfo(userId);
    }, [userId]);

    if (!user) {
        return null;
    }

    const { avatar_url, displayname } = user;

    const displayName = displayname || userId;

    const avatarLetter = displayName?.startsWith('@') ? displayName[1] : displayName[0];
    const avatarStyle = avatar_url ? undefined : { color: theme.palette.common.white, backgroundColor: userColor };

    return (
        <ListItem
            {...props}
            button={(onClick ? true : false) as any}
            onClick={onClick ? () => onClick({ avatar_url, displayName, userId }) : undefined}
            className={classes.listItem}
            selected={selected}
        >
            <ListItemAvatar className={classes.avatarContainer}>
                <Avatar className={classes.avatar} src={avatar_url || undefined} style={avatarStyle}>
                    {avatarLetter}
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography variant="h6">{displayName}</Typography>
            </ListItemText>
        </ListItem>
    );
};
