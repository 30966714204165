import Olm from '@matrix-org/olm';
import { EventEmitter } from 'events';
import { createClient, IndexedDBCryptoStore, IndexedDBStore } from 'matrix-js-sdk';
import { logger } from 'matrix-js-sdk/lib/logger';
import { IStore } from 'matrix-js-sdk/lib/store';
import { WebStorageSessionStore } from 'matrix-js-sdk/lib/store/session/webstorage';
import { ConfigStore } from './config-store';
import { constants } from './constants';
import { initMatrix, matrixClient } from './matrix-client';
import { MatrixEventType } from './types';

logger.setLevel(logger.levels.ERROR);

export const INDEXED_DB_STORE_NAME = 'web-sync-store';
export const INDEXED_DB_CRYPTO_STORE_NAME = 'crypto-store';

export class MatrixSetup extends EventEmitter {
    constructor(private readonly baseUrl: string) {
        super();
    }

    async init(): Promise<void> {
        await this.loadOlm();
        await this.startClient();
        this.setupSync();
        this.listenEvents();
    }

    async login(userId: string, password: string): Promise<void> {
        const client = createClient({ baseUrl: this.baseUrl });

        const response = await client.login('m.login.password', {
            identifier: {
                type: 'm.id.user',
                user: `@${userId}:matrix.thelobbylifestyle.com`,
            },
            password,
            initial_device_display_name: constants.DEVICE_DISPLAY_NAME,
        });

        ConfigStore.setMatrixAuthData({
            home_server: 'matrix.thelobbylifestyle.com',
            access_token: response.access_token,
            user_id: response.user_id,
            device_id: response.device_id,
        });
    }

    async logout(): Promise<void> {
        matrixClient?.logout();
    }

    private async loadOlm(): Promise<void> {
        await Olm.init({ locateFile: () => '/olm.wasm' });
    }

    private async startClient(): Promise<void> {
        const matrixAuthData = ConfigStore.getMatrixAuthData();

        if (!matrixAuthData) {
            console.error('MatrixAuthData is undefined');
            return;
        }

        const indexedDBStore = new IndexedDBStore({
            indexedDB,
            localStorage,
            dbName: INDEXED_DB_STORE_NAME,
        });
        await indexedDBStore.startup();

        const client = createClient({
            baseUrl: this.baseUrl,
            accessToken: matrixAuthData.access_token,
            userId: matrixAuthData.user_id,
            deviceId: matrixAuthData.device_id,
            store: indexedDBStore as IStore,
            sessionStore: new WebStorageSessionStore(localStorage),
            cryptoStore: new IndexedDBCryptoStore(indexedDB, INDEXED_DB_CRYPTO_STORE_NAME),
            timelineSupport: true,
        });

        await client.initCrypto();

        await client.startClient({ lazyLoadMembers: true, initialSyncLimit: 20 });
        client.setGlobalErrorOnUnknownDevices(false);

        initMatrix(client);
    }

    private setupSync(): void {
        const sync = {
            NULL: (): void => {
                // console.log('NULL state');
            },
            SYNCING: (): void => {
                // console.log('SYNCING state');
            },
            PREPARED: (prevState): void => {
                // console.log('PREPARED state');
                // console.log('previous state: ', prevState);

                if (prevState === null) {
                    this.emit('init_loading_finished');
                }
            },
            RECONNECTING: (): void => {
                // console.log('RECONNECTING state');
            },
            CATCHUP: (): void => {
                // console.log('CATCHUP state');
            },
            ERROR: (error): void => {
                // console.log('ERROR state');
                console.error(error);
            },
            STOPPED: (): void => {
                // console.log('STOPPED state');
            },
        };
        matrixClient.on(MatrixEventType.Sync, (state, prevState) => sync[state](prevState));
    }

    private listenEvents(): void {
        matrixClient.on(MatrixEventType.SessionLoggedOut, () => {
            matrixClient.stopClient();
            matrixClient.clearStores();
            ConfigStore.clearMatrixAuthData();
        });
    }
}
