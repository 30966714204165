import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            cursor: 'pointer',
            height: '100%',
        },
        textSecondary: {
            color: theme.palette.text.secondary,
        },
    };
});
