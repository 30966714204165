import { darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        button: {
            height: 44,
        },
        field: {
            '& .matrix-MuiOutlinedInput-multiline': {
                paddingTop: '13.5px',
                paddingBottom: '13.5px',
                boxSizing: 'border-box',
            },
        },
        listItem: {},
        listItemSelected: {
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
        },
        avatarContainer: {
            minWidth: 'unset',
            marginRight: theme.spacing(2),
        },
        avatar: {
            width: 36,
            height: 36,
        },
    };
});
