import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;

    return {
        root: {
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(2),
            boxShadow: `0px 1px 4px 0px ${isDarkMode ? theme.shadows[0] : '#959DA529'}`,
        },
        title: {
            padding: theme.spacing(1, 2),
            fontWeight: 400,
            fontSize: '.875rem',
            color: theme.palette.text.secondary,
        },
        content: {
            padding: theme.spacing(1, 2),
        },
    };
});
