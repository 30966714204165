import { PaletteType } from '@material-ui/core';
import React, { createContext, useContext } from 'react';
import { useLocalStorage } from 'react-use';

export const THEME_KEY = '@Settings/theme';
const DEFAULT_THEME = 'light';

type SettingsContextProps = {
    isDarkMode: boolean;
    theme?: PaletteType;
    toggleTheme: () => void;
};

const SettingsContext = createContext<SettingsContextProps>({
    isDarkMode: false,
    theme: DEFAULT_THEME,
    toggleTheme: () => {
        //
    },
});
export const useSettings = (): SettingsContextProps => useContext<SettingsContextProps>(SettingsContext);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [theme, setTheme] = useLocalStorage<PaletteType>(THEME_KEY, DEFAULT_THEME, { raw: true });

    const isDarkMode = theme === 'dark';
    const toggleTheme = (): void => setTheme(isDarkMode ? 'light' : 'dark');

    return (
        <SettingsContext.Provider
            value={{
                isDarkMode,
                theme,
                toggleTheme,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
