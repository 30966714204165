import React from 'react';
import { SettingSectionItem } from '../components';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';

type SecurityProps = {
    setActiveTab: (value: React.SetStateAction<string>) => void;
};
export const Security: React.FC<SecurityProps> = ({ setActiveTab }) => {
    return (
        <SettingSectionItem
            onClick={() => setActiveTab('security')}
            icon={<SecurityOutlinedIcon />}
            title="Security"
            disabled
        />
    );
};
