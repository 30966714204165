import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(1),
            //TEMPORARY
            marginBottom: theme.spacing(4),
            flex: 1,
        },
        title: {
            padding: theme.spacing(1),
        },
    };
});
