import { Button, Drawer, DrawerProps, useTheme, Container, useMediaQuery } from '@material-ui/core';
import { useRoom } from 'chat/hooks/use-room';
import React, { useState, useCallback } from 'react';
import { useStyles } from './settings.style';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import { Formik } from 'formik';
import { Header } from './header';
import { AvatarSection } from './avatar-section';
import { SettingSection } from './setting-section';
import { Members } from './members';
import { Security } from './security';

type SettingsProps = DrawerProps & {
    roomId: string;
};

export const Settings: React.FC<SettingsProps> = ({ roomId, onClose, ...props }) => {
    const theme = useTheme();
    const { name, members } = useRoom(roomId);
    const classes = useStyles();

    const closeMemberList = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeTab, setActiveTab] = useState<string>('members');

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            elevation={1}
            hideBackdrop
            className={classes.root}
            {...props}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                container: document.getElementById('room-view-container'),
                style: { position: 'absolute' },
            }}
            onClose={onClose}
        >
            <Formik
                initialValues={{ title: name }}
                onSubmit={() => {
                    //
                }}
            >
                {({ values, setFieldValue, dirty, handleSubmit }) => {
                    return (
                        <>
                            <Header closeMemberList={closeMemberList} dirty={dirty} handleSubmit={handleSubmit} />
                            <AvatarSection roomId={roomId} values={values} setFieldValue={setFieldValue} />
                        </>
                    );
                }}
            </Formik>
            <Container maxWidth={isDownSm ? 'xs' : 'sm'} className={classes.container}>
                <SettingSection setActiveTab={setActiveTab} roomId={roomId} />
                {activeTab === 'members' && <Members roomId={roomId} members={members} />}
                {activeTab === 'security' && (
                    <>
                        <Button
                            onClick={() => setActiveTab('members')}
                            startIcon={<ChevronLeftOutlinedIcon />}
                            className={classes.backToMembers}
                        >
                            Members
                        </Button>
                        <Security />
                    </>
                )}
            </Container>
        </Drawer>
    );
};
