import { Avatar, Box, Button, Paper, Typography, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useChat } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback } from 'react';
import { useStyles } from './room-invite-view.style';

type RoomInviteViewProps = {
    roomId: string;
};

export const RoomInviteView: React.FC<RoomInviteViewProps> = ({ roomId }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [{ joinRoomLoading, joinRoomError, leaveRoomLoading, leaveRoomError }, dispatch] = useChat();

    const { name, color, avatarUrl, avatarLetter } = useRoom(roomId);

    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    const joinRoom = useCallback(() => dispatch({ type: 'JOIN_ROOM', roomId }), [dispatch, roomId]);
    const leaveRoom = useCallback(() => dispatch({ type: 'LEAVE_ROOM', roomId }), [dispatch, roomId]);

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h4">Do you want to join {name}?</Typography>
                <Box mt={4}>
                    <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle}>
                        {avatarLetter}
                    </Avatar>
                </Box>
                {!!joinRoomError && (
                    <Box mt={4}>
                        <Alert severity="error">{joinRoomError}</Alert>
                    </Box>
                )}
                {!!leaveRoomError && (
                    <Box mt={4}>
                        <Alert severity="error">{leaveRoomError}</Alert>
                    </Box>
                )}
                <Box
                    width="100%"
                    mt={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        onClick={joinRoom}
                        disabled={joinRoomLoading || leaveRoomLoading}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                    >
                        Accept
                    </Button>
                    <Button
                        onClick={leaveRoom}
                        disabled={joinRoomLoading || leaveRoomLoading}
                        className={classes.button}
                        color="primary"
                    >
                        Reject
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};
